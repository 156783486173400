import React from 'react';
import { CharacterRulesList, GearRulesList, SpeciesRulesList, MagicRulesList, MiscRulesList } from 'components';

const RulesList = () => {
  const sections = [
    { title: 'Characters', Component: CharacterRulesList},
    { title: 'Species', Component: SpeciesRulesList },
    { title: 'Equipment', Component: GearRulesList },
    { title: 'Magic', Component: MagicRulesList },
    { title: 'Misc', Component: MiscRulesList },
  ];


  return (
    <div>
      <h1>Rules</h1>
      <hr className='hr-small' />
      <div className='four-column center'>
        {sections.map(({ title, Component }) => (
          <div key={title}>
            <h2>{title}</h2>
            <Component 
              render={content => {
                // Don't render anything if it's an empty message
                if (typeof content === 'string' && content.startsWith('No') && content.endsWith('found')) {
                  return null;
                }
                return content;
              }} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RulesList;