// client/src/components/user/friends/FriendSearch.js
import React, { useState } from 'react';
import axios from 'axios';
import { FriendDisplay, Protected } from 'components';

const FriendSearch = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!query.trim()) return;

        setLoading(true);
        try {
            const response = await axios.get(`/api/user/search?query=${encodeURIComponent(query)}`);
            setResults(response.data);
            setError('');
        } catch (err) {
            setError('Failed to search for users');
            setResults([]);
        } finally {
            setLoading(false);
        }
    };

    const handleAddFriend = async (userId) => {
        try {
            await axios.post(`/api/user/friends/request/${userId}`, {}, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            
            setResults(results.map(user => 
                user._id === userId 
                    ? { ...user, requestSent: true }
                    : user
            ));
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to send friend request');
        }
    };

    return (
        <Protected>
            <div className="friend-search ">

                    <form className='two-column no-padding fill' onSubmit={handleSearch}>                        
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Search by name or alias"
                        />

                    </form>



                {loading && <div>Searching...</div>}
                {error && <div className="error">{error}</div>}
                
                <div className="search-results">
                    {results.map(user => (
                        <div key={user._id} className="inline-flex">

                            {!user.requestSent && (
                                <>
                                    <span>&nbsp;</span>
                                    <FriendDisplay
                                        friend={user}
                                        showPicture={false}
                                        showAddFriend={true}
                                        onAddFriend={handleAddFriend}
                                        showActions={true}
                                        showName={false}
                                        showAlias={true}
                                    />
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </Protected>
    );
};

export default FriendSearch;