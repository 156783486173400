// client/src/hooks/useMarkdown.js
import { renderToString } from 'react-dom/server';
import ReactMarkdown from 'react-markdown';

export const useMarkdown = () => {
  const renderMarkdown = (content) => {
    if (!content) return '';
    return renderToString(<ReactMarkdown>{content}</ReactMarkdown>);
  };

  const parseMarkdown = (content) => {
    if (!content) return null;
    return <ReactMarkdown>{content}</ReactMarkdown>;
  };

  return { renderMarkdown, parseMarkdown };
};