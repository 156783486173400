// src/pages/lists/FictionList.js
import React from 'react';
import { ShortStoryList } from 'components';

const FictionList = () => {
  const sections = [
    { title: '', Component: ShortStoryList },
  ];

  return (
    <div>
      <h1>Fiction</h1>
      <hr className='hr-small' />
      <div className='two-column center'>
        {sections.map(({ title, Component }) => (
          <React.Fragment key={title}>
            <Component render={content => {
              // If the content is empty message, don't render the div
              if (content === "No stories found") {
                return null;
              }
              
              // Otherwise, render the section with its title and content
              return (
                <div>
                  <h2>{title}</h2>
                  {content}
                </div>
              );
            }} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FictionList;