// client/src/components/pages/PageDetails.js
import React from 'react';
import { DeleteIcon, CreateIcon } from 'components';
import './pageDetails.css'

function PageDetails({ details, onChange }) {
    const addDetail = () => {
        const newDetail = {
            label: '',
            value: '' 
        };
        onChange([...details, newDetail]); 
    };

    const updateDetail = (index, field, value) => {
        const updatedDetails = [...details];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [field]: value
        };
        onChange(updatedDetails);
    };

    const deleteDetail = (index) => {
        onChange(details.filter((_, i) => i !== index));
    };

    return (
        <div className="page-details">
            <div className='two-column'>
                <div>
                    <button 
                        type="button" 
                        onClick={addDetail} 
                        className="icon i24">
                            <CreateIcon /> 
                    </button>
                </div>
                <div>
                    {details.map((detail, index) => (        
                        <div key={index} className="three-column">
                            <div className='grid-item'>
                                    <label htmlFor={`detail-label-${index}`}></label>
                                        <input
                                            type="text"
                                            id={`detail-label-${index}`}
                                            value={detail.label || ''}
                                            onChange={(e) => updateDetail(index, 'label', e.target.value)}
                                            placeholder="Label"
                                            className='small-label'
                                        />
                            </div>
                            <div className='grid-item'>
                                    <label htmlFor={`detail-value-${index}`}></label>
                                        <input
                                            type="text"
                                            id={`detail-value-${index}`}
                                            value={detail.value || ''}
                                              onChange={(e) => updateDetail(index, 'value', e.target.value)}
                                            placeholder="Value"
                                            className='big-desc'
                                        />
                            </div>
                            <div className='grid-item'>
                                    <button 
                                    type="button" 
                                    onClick={() => deleteDetail(index)}
                                    className="icon i24"
                                    >
                                        <DeleteIcon />
                                    </button>
                            </div>
                        </div> 
                        ))}
                </div>

            </div>
        </div>
    );
}

export default PageDetails;