import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApi } from 'context';
import { BroadcastWrapper, PageTab, PageTabContainer, PageDetails, PageSections, MarkdownEditor } from 'components'; 
import { featTemplate } from '../../../templates/pageTemplates';
import { useFormDirty } from 'hooks';

function CreatePage({ template }) {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const initialValues = location.state?.initialValues || {
      name: '',
      type: '',
      subType: '',
      desc: ''
  };

  const [error, setError] = useState('');
  const [page, setPage] = useState(initialValues);
  const isFormDirty = useFormDirty(page);

  const templates = {
      feat: featTemplate
  };
  
  const subtypeOptions = {
      Fiction: ['Short Story', 'History'],
      Place: ['Nation', 'City', 'POI'],
      Rule: ['Ability', 'Character', 'Equipment', 'Feat', 'Species', 'Magic', 'Spell'],
      Person: ['NPC','PC'],
      Site: ['Update', 'Header', 'Footer'],
      Game: ['Character','Campaign']
  };

  const handleChange = (e) => {
      const { name, value } = e.target;
      setPage(prev => {
          if (name === 'type') {
              return {
                  ...prev,
                  [name]: value,
                  subType: ''
              };
          }
          return {
              ...prev,
              [name]: value
          };
      });
  };

  const handleCancel = () => {
      navigate(-1);
  };

  return (
      <div className="create-page-container fill75 center">
          <h1>Create Page</h1>
          
          {error && (
              <div className="error-message">
                  {error}
              </div>
          )}

           <BroadcastWrapper
               shouldBroadcast={(pageData) => pageData.type === 'Site' && pageData.subType === 'Update'}
               formatMessage={(pageData) => `A new site update has been posted. [Click here](/pages/${pageData._id}) to read it.`}
onSuccess={async (e) => {
    const response = await api.post('/api/pages', page);
    if (response.status === 201) {
        navigate(`/pages/${response.data._id}`);
    }
    return response;
}}
               onError={(err) => setError(err.response?.data?.error || 'An error occurred while creating the page')}
           >

           {/* ------------------------------------------------------------------------------- */}
           {/* Top Row                                                                         */}
           {/* ------------------------------------------------------------------------------- */}

              <div className="create-page-form three-column">
                  <div className="grid-item">
                      <input
                          type="text"
                          id="name"
                          name="name"
                          value={page.name}
                          onChange={handleChange}
                          required
                          maxLength={100}
                          placeholder='Name'
                      />
                  </div>

                  <div className='grid-item'>
                      <select
                          id="type"
                          name="type"
                          value={page.type}
                          onChange={handleChange}
                          className='select-menu'
                          required
                      >
                          <option value="">Type</option> 
                          <option value="Fiction">Fiction</option>
                          <option value="Place">Place</option>
                          <option value="Rule">Rule</option>
                          <option value="Person">Person</option>
                          <option value="Site">Site</option>
                          <option value="Game">Game</option>
                      </select>
                  </div>

                  <div className="grid-item">
                      <select
                          id="subType"
                          name="subType"
                          value={page.subType}
                          onChange={handleChange}
                          className='select-menu'
                          required
                          disabled={!page.type}
                      >
                          <option value="">Subtype</option>
                          {page.type && subtypeOptions[page.type].map(subtype => (
                              <option key={subtype} value={subtype}>
                                  {subtype}
                              </option>
                          ))}
                      </select>
                  </div>
              </div>

              <div className="floating-action-button-container">
                  <button 
                      type="button" 
                      className="floating-action-button secondary" 
                      onClick={handleCancel}
                  >
                      Cancel
                  </button>
                  <button 
                      type="submit" 
                      className={`floating-action-button ${!isFormDirty ? 'secondary' : ''}`}
                      disabled={!isFormDirty}
                  >
                      Create Page
                  </button>
              </div>
           </BroadcastWrapper>
      </div>
  );
}

export default CreatePage;