// client/src/components/pages/PageFields.js
import React from 'react';
import { MarkdownEditor, CollapsiblePanel, TagInput } from 'components';


function PageFields({ page, onChange }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({ ...page, [name]: value });
    };

    const handleTagsChange = (e) => {
        const values = e.target.value.split(',').map(item => item.trim());
        onChange({ ...page, tags: values });
    };

    const handleTriggerChange = (e) => {
        const { checked } = e.target;
        onChange({ ...page, trigger: checked });
    };

    const handleLinkChange = (e) => {
        const { name, value } = e.target;
        onChange({
            ...page,
            link: {
                ...page.link,
                [name === 'link' ? 'key' : 'url']: value
            }
        });
    };

    return (
        <div className="page-fields">
            <div className='three-column'>   

{/* ------------------------------------------------------------------------- */}
{/* Row 1                                                                     */}
{/* ------------------------------------------------------------------------- */}

                <div className="form-group">
                    <label htmlFor="name" className='left'>Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={page.name || ''}
                        onChange={handleChange}
                        required
                        maxLength={100}
                        placeholder='Name'
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="type" className='left'>Type</label>
                    <input
                        type="text"
                        id="type"
                        name="type"
                        value={page.type || ''}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="subType" className='left'>Subtype</label>
                    <input
                        type="text"
                        id="subType"
                        name="subType"
                        value={page.subType || ''}
                        onChange={handleChange}
                        required
                        placeholder='Subtype'
                    />
                </div>

{/* ------------------------------------------------------------------------- */}
{/* Row 2                                                                     */}
{/* ------------------------------------------------------------------------- */}

                <div className="form-group">
                    <label htmlFor="link" className='left'>Link Key</label>
                    <input
                        type="text"
                        id="link"
                        name="link"
                        value={page.link?.key || ''}
                        onChange={handleLinkChange}
                        placeholder='e.g., feats, stories'
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="linkUrl" className='left'>URL</label>
                    <input
                        type="text"
                        id="linkUrl"
                        name="linkUrl"
                        value={page.link?.url || ''}
                        onChange={handleLinkChange}
                        placeholder='e.g., /feats'
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="pageImg" className='left'>Image URL</label>
                    <input
                        type="text"
                        id="pageImg"
                        name="pageImg"
                        value={page.pageImg || ''}
                        onChange={handleChange}
                        placeholder='Image URL'
                    />
                </div>
            </div>

{/* ------------------------------------------------------------------------- */}
{/* Description                                                               */}
{/* ------------------------------------------------------------------------- */}

            <CollapsiblePanel title="Description">
                <div className="form-group">
                    <MarkdownEditor
                        value={page.desc || ''}
                        onChange={handleChange}
                        label=""
                        name="desc"
                    />
                </div>
            </CollapsiblePanel>

{/* ------------------------------------------------------------------------- */}
{/* Sidebar                                                                   */}
{/* ------------------------------------------------------------------------- */}
            <CollapsiblePanel title="Sidebar">
                <div className='three-column-fixed'>
                    <div className="form-group left">
                        <input
                            type="text"
                            id="secName"
                            name="secName"
                            value={page.secName || ''}
                            onChange={handleChange}
                            placeholder='Title'
                        />
                    </div>

                    <div className='form-group'></div>
                    <div className='form-group'></div>

                    <br/>
                    
                    <div className="form-group full-width">
                        <MarkdownEditor
                            value={page.secDesc || ''}
                            onChange={handleChange}
                            name="secDesc"
                        />
                    </div>
                </div>
            </CollapsiblePanel>


{/* ------------------------------------------------------------------------- */}
{/* Misc Page Detail                                                          */}
{/* ------------------------------------------------------------------------- */}

            <CollapsiblePanel title="Misc">
                <div className='left div-indent'>

                    <div className="">
                            <label htmlFor="active">
                                <input
                                    type="checkbox"
                                    id="active"
                                    name="active"
                                    checked={page.active !== false}
                                    onChange={(e) => handleChange({
                                        target: { name: 'active', value: e.target.checked }
                                    })}
                                />
                                {' '}Active
                            </label>
                    </div>  

                    <div className="">
                        <label htmlFor="trigger" className=''>
                            <input
                                type="checkbox"
                                id="trigger"
                                name="trigger"
                                checked={page.trigger || false}
                                onChange={handleTriggerChange}
                            />
                            {' '}Trigger Warning
                        </label>
                    </div>

                    {page.trigger && (
                        <div className="">
                            <MarkdownEditor
                                value={page.triggerDesc || ''}
                                onChange={handleChange}
                                name="triggerDesc"
                                required
                                className=''
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="sortOrder">Sort Order: </label>
                        <input
                            type="number"
                            id="sortOrder"
                            name="sortOrder"
                            value={page.sortOrder || 0}
                            onChange={handleChange}
                        />
                    </div>

                </div>

            </CollapsiblePanel>

{/* ------------------------------------------------------------------------- */}
{/* Tags                                                                      */}
{/* ------------------------------------------------------------------------- */}

            <div className="div-indent">
                <TagInput 
                    tags={page.tags || []} 
                    onChange={(newTags) => onChange({ ...page, tags: newTags })}
                />
            </div>

        </div>
    );
}

export default PageFields;