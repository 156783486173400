// client/src/components/content/CampaignSetup.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { useParams } from 'react-router-dom';
import { AdminProtected, ContributorProtected, OwnerProtected } from 'components/auth/Protected';

const GAME_SYSTEMS = [
  'D&D 5E',
  'Pathfinder',
  'Pathfinder 2E',
  'Call of Cthulhu',
  'Savage Worlds',
  'GURPS',
  'Starfinder',
  'Custom'
];

const CampaignSetup = ({ page }) => {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [dmInput, setDmInput] = useState('');
  const [selectedSystem, setSelectedSystem] = useState('');
  const [customSystem, setCustomSystem] = useState('');
  const [showCustomDm, setShowCustomDm] = useState(false);
  const [showCustomSystem, setShowCustomSystem] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${config.serverUrl}/api/user/search?query=a`,  // 'a' as minimal query to get initial list
          { withCredentials: true }
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
        setError('Failed to fetch users');
      }
    };
    fetchUsers();
}, []);



  const updatePageDetail = async (key, value) => {
    try {
      const response = await axios.get(`${config.serverUrl}/api/pages/${id}`, { withCredentials: true });
      const currentPage = response.data;
      
      const updatedDetails = [...(currentPage.details || [])];
      const existingIndex = updatedDetails.findIndex(detail => detail.label === key);
      
      if (existingIndex >= 0) {
        updatedDetails[existingIndex].value = value;
      } else {
        updatedDetails.push({ label: key, value: value });
      }

      await axios.put(
        `${config.serverUrl}/api/pages/${id}`,
        { ...currentPage, details: updatedDetails },
        { withCredentials: true }
      );
    } catch (err) {
      console.error('Failed to update detail:', err);
      setError('Failed to update campaign detail');
    }
  };

  const handleDmChange = async (value) => {
    if (value === 'custom') {
      setShowCustomDm(true);
      setDmInput('');
    } else {
      setShowCustomDm(false);
      setDmInput(value);
      await updatePageDetail('DM', value);
    }
  };

  const handleSystemChange = async (value) => {
    setSelectedSystem(value);
    if (value === 'Custom') {
      setShowCustomSystem(true);
      setCustomSystem('');
    } else {
      setShowCustomSystem(false);
      await updatePageDetail('Rule System', value);
    }
  };

  const handleCustomDmInput = async (value) => {
    setDmInput(value);
    await updatePageDetail('DM', value);
  };

  const handleCustomSystemInput = async (value) => {
    setCustomSystem(value);
    await updatePageDetail('Rule System', value);
  };

  return (
    <div className="campaign-setup">
      <ContributorProtected  page={page}>
        <div className="dm-selection">
          <label className="">DM/GM: </label>
          <select 
            className=""
            value={showCustomDm ? 'custom' : dmInput}
            onChange={(e) => handleDmChange(e.target.value)}
          >
            <option value="">Select DM/GM</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>{user.alias}</option>
            ))}
            <option value="custom">Other (Please Specify)</option>
          </select>
          {showCustomDm && (
            <input
              type="text"
              className="="
              placeholder="Enter DM/GM name"
              value={dmInput}
              onChange={(e) => handleCustomDmInput(e.target.value)}
            />
          )}
        </div>

        <div className="system-selection">
          <label className="">Rule System</label>
          <select
            className=""
            value={selectedSystem}
            onChange={(e) => handleSystemChange(e.target.value)}
          >
            <option value="">Rule System</option>
            {GAME_SYSTEMS.map(system => (
              <option key={system} value={system}>{system}</option>
            ))}
          </select>
          {showCustomSystem && (
            <input
              type="text"
              className=""
              placeholder="Enter custom system name"
              value={customSystem}
              onChange={(e) => handleCustomSystemInput(e.target.value)}
            />
          )}
        </div>
      </ContributorProtected> 
    </div>
  );
};

export default CampaignSetup;