// client/src/pages/lists/embers/EmbersFeatsList.js
import React from 'react';
import { EmbersFeats } from 'components';

const EmbersFeatsList = () => (
  <div className="page">
    <EmbersFeats 
       ruleset="5th Edition"
       showActive={true}
       showStock={false}  
       source="veylantia.com" 
       displayStyle='h2'
       showFields={['name', 'description']} 
       titleClassName='left'  
       descriptionClassName='left'  
       embersUserId="58f39ce7bdc1ae0004dbed3d"
    />
  </div>
);

export default EmbersFeatsList;