// client/src/components/user/building-blocks/UserBlock.js
import React from 'react';
import { useAuth } from 'context';
import { MarkdownRenderer } from 'components';

/******************************************************************************
* PROP TYPES DEFINITION
* Defines accepted props and their default values for field handling
******************************************************************************/
const defaultDateFields = ['createdAt', 'updatedAt'];
const defaultImageFields = ['picture'];
const defaultLinkFields = ['email'];
const defaultExcludeFields = ['isAdmin', '_id', '__v', 'password'];

/******************************************************************************
* COMPONENT DEFINITION
* A reusable component for displaying user data with configurable output
* Props:
* - include: fields to show (if empty, shows all non-excluded fields)
* - exclude: fields to hide (defaults to sensitive fields)
* - inline: display as inline vs block
* - noDefaultStyles: removes all default styling
* - titleLevel: heading level for name field (h1-h6, span)
* - unwrapDivs: removes div wrappers around fields
* - className: custom CSS classes
* - avatarSize: size for picture field
* - markdownFields: fields to render as markdown
* - dateFields: fields to format as dates
* - imageFields: fields to render as images
* - linkFields: fields to render as links
******************************************************************************/
const UserBlock = ({
    include = [],
    exclude = defaultExcludeFields,
    inline = false,
    noDefaultStyles = false,
    titleLevel = 'h1',
    unwrapDivs = false,
    className = '',
    avatarSize = 'medium',
    markdownFields = [],
    dateFields = defaultDateFields,
    imageFields = defaultImageFields,
    linkFields = defaultLinkFields
}) => {
    const { user } = useAuth();
    if (!user) return null;

    /******************************************************************************
    * FIELD VISIBILITY LOGIC
    * Determines which fields should be displayed based on include/exclude props
    ******************************************************************************/
    const shouldShowField = (fieldName) => {
        if (include.length > 0) {
            return include.includes(fieldName) && !exclude.includes(fieldName);
        }
        return !exclude.includes(fieldName);
    };

    /******************************************************************************
    * TITLE RENDERING
    * Handles rendering of the user's name with configurable heading level
    ******************************************************************************/
    const renderTitle = () => {
        switch(titleLevel) {
            case 'h2': return <h2>{user.name}</h2>;
            case 'h3': return <h3>{user.name}</h3>;
            case 'h4': return <h4>{user.name}</h4>;
            case 'h5': return <h5>{user.name}</h5>;
            case 'h6': return <h6>{user.name}</h6>;
            case 'span': return <span>{user.name}</span>;
            default: return <h1>{user.name}</h1>;
        }
    };

    /******************************************************************************
    * FIELD VALUE RENDERING
    * Handles rendering of different field types:
    * - Special fields (name)
    * - Images (avatar)
    * - Dates (formatted)
    * - Links (clickable)
    * - Markdown (rendered)
    * - Arrays (joined)
    * - Objects (stringified)
    * - Default text
    ******************************************************************************/
    const renderField = (fieldName, value) => {
        if (!shouldShowField(fieldName)) return null;

        if (fieldName === 'name') {
            return renderTitle();
        }

        if (imageFields.includes(fieldName)) {
            return <img src={value} alt={user.name} />;
        }

        if (dateFields.includes(fieldName)) {
            return <div>{new Date(value).toLocaleDateString()}</div>;
        }

        if (linkFields.includes(fieldName)) {
            return <a href={`mailto:${value}`}>{value}</a>;
        }

        if (markdownFields.includes(fieldName)) {
            return <MarkdownRenderer content={value} />;
        }

        if (Array.isArray(value)) {
            return <div>{value.join(', ')}</div>;
        }

        if (typeof value === 'object' && value !== null) {
            return <pre>{JSON.stringify(value, null, 2)}</pre>;
        }

        return <div>{String(value)}</div>;
    };

    /******************************************************************************
    * CONTENT ASSEMBLY
    * Puts together all visible fields with appropriate wrapper elements
    * Filters out:
    * - Function fields
    * - Null/undefined values
    * - Fields that shouldn't be shown based on include/exclude
    ******************************************************************************/
    const renderContent = () => {
        return Object.entries(user)
            .filter(([key]) => typeof user[key] !== 'function')
            .map(([key, value]) => {
                if (!value) return null;
                
                const content = renderField(key, value);
                if (!content) return null;

                return unwrapDivs ? (
                    <React.Fragment key={key}>
                        {content}
                    </React.Fragment>
                ) : (
                    <div key={key} className={className}>
                        {content}
                    </div>
                );
            });
    };

    /******************************************************************************
    * COMPONENT RENDER
    * Final assembly of the component with optional wrapper
    * Controls:
    * - Inline vs block display
    * - Div wrapping
    * - Custom className application
    ******************************************************************************/
    return unwrapDivs ? (
        <>{renderContent()}</>
    ) : (
        <div 
            className={className}
            style={{ display: inline ? 'inline' : 'block' }}
        >
            {renderContent()}
        </div>
    );
};

export default UserBlock;