// components/shared/AlphabeticalList.js
import React from 'react';

const AlphabeticalList = ({ items, renderItem, renderHeader, renderFooter,   itemsContainerClassName = '' }) => {
  // Group items by first letter
  const groupedItems = items.reduce((acc, item) => {
    if (!item?.name) return acc;
    const firstLetter = item.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});

  // Sort groups alphabetically
  const sortedLetters = Object.keys(groupedItems).sort();

  return (
    <div className="alphabetical-list">
      {sortedLetters.map(letter => (
        <div key={letter} className="letter-group">
          {renderHeader && renderHeader(letter)}
          <div className={itemsContainerClassName}>
            {groupedItems[letter]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(item => renderItem(item))}
          </div>
          {renderFooter && renderFooter(letter)}
        </div>
      ))}
    </div>
  );
};


export default AlphabeticalList;