// client/src/components/shared/EmbersBasePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MarkdownRenderer } from 'components';
import { useAuth } from 'context';

const EmbersBasePage = ({ 
 type,
 ruleset,
 loadingMessage = 'Loading...', 
 emptyMessage = 'No items found',
 itemWrapper = 'div',
 listWrapper = 'div',
 displayFields = ['name'],
 className = '',
 render 
}) => {
 const [items, setItems] = useState([]);
 const [error, setError] = useState(null);
 const [loading, setLoading] = useState(true);
 const { user } = useAuth();

 useEffect(() => {
   const fetchItems = async () => {
     if (!user) {
       setLoading(true);
       return;
     }

     if (!user.embersId) {
       setError('No Embers account linked');
       setLoading(false);
       return;
     }

     try {
       setError(null);
       const params = ruleset ? { ruleset } : {};
       const response = await axios.get(
         `/api/embers/${type}/${user.embersId}`, 
         { params }
       );
       setItems(response.data);
       setLoading(false);
     } catch (err) {
       setError(`Failed to load ${type.toLowerCase()}`);
       setLoading(false);
       console.error(`Error fetching ${type.toLowerCase()}:`, err);
     }
   };

   fetchItems();
 }, [type, user, ruleset]);

 const ItemWrapperComponent = itemWrapper;
 const ListWrapperComponent = listWrapper;

 const content = loading ? (
   <div className="embers-loading">{loadingMessage}</div>
 ) : error ? (
   <div className="embers-error">{error}</div>
 ) : items.length === 0 ? (
   <div className="embers-empty">{emptyMessage}</div>
 ) : (
   <ListWrapperComponent className={`embers-list ${className}`}>
     {items.map(item => (
       <ItemWrapperComponent key={item._id} className="embers-item">
         {displayFields.includes('name') && (
           <h3 className="embers-item-name">{item.name}</h3>
         )}
         {displayFields.includes('core') && item.core && (
           <div className="embers-item-details">
             <div className="embers-item-class">
               {item.core.class} - Level {item.core.experience}
             </div>
             <div className="embers-item-race">
               {item.core.race} - {item.core.alignment}
             </div>
           </div>
         )}
         {item.image?.url && displayFields.includes('image') && (
           <img 
             src={item.image.url}
             alt={item.name}
             className="embers-item-image"
           />
         )}
         {item.description && displayFields.includes('description') && (
           <MarkdownRenderer 
             content={item.description}
             className="embers-item-description"
           />
         )}
       </ItemWrapperComponent>
     ))}
   </ListWrapperComponent>
 );

 return render ? render(content) : content;
};

export default EmbersBasePage;