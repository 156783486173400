// client/src/components/auth/Protected.js
import React from 'react';
import { useAuth } from 'context';

/******************************************************************************
 * AUTHENTICATION NOTICES 
 * These are the messages shown to users based on their access level
 ******************************************************************************/
const LOGIN_REQUIRED_MESSAGE = "Please log in to view this content.";
const MEMBERS_ONLY_MESSAGE = "This content is only available to members.";

/******************************************************************************
 * PERMISSION CHECKING UTILITIES
 * Centralized permission checks used by all protection components
 * Each returns a boolean indicating if the user has that access level
 ******************************************************************************/

// Checks if a user has admin privileges
const checkIsAdmin = (user) => user?.isAdmin === true;

// Checks if a user is the owner of a page
const checkIsOwner = (user, page) => {
    if (!user || !page?.owner) return false;
    return user._id.toString() === page.owner.toString();
};

// Checks if a user is a contributor to a page
const checkIsContributor = (user, page) => {
    if (!user || !page?.contributors) return false;
    return page.contributors.some(id => id?.toString() === user._id.toString());
};

// Checks if a user is a member of a page
const checkIsMember = (user, page) => {
    if (!user || !page?.members) return false;
    return page.members.some(id => id?.toString() === user._id.toString());
};

/******************************************************************************
 * BASIC PROTECTION
 * Simple logged-in user check with no additional permissions
 * Used for content that just needs a logged-in user
 ******************************************************************************/
const Protected = ({ children }) => {
    const { user } = useAuth();
    if (!user) {
        return <div>{LOGIN_REQUIRED_MESSAGE}</div>;
    }
    return children;
};

/******************************************************************************
 * ADMIN PROTECTION
 * Requires user to have admin privileges
 * Most restrictive level - only admins can see this content
 ******************************************************************************/
const AdminProtected = ({ children }) => {
    const { user } = useAuth();
    if (!checkIsAdmin(user)) return null;
    return children;
};

/******************************************************************************
 * OWNER PROTECTION
 * Requires user to be admin or the owner of the content
 * Allows: Admins, Owners
 ******************************************************************************/
const OwnerProtected = ({ children, page }) => {
    const { user } = useAuth();
    if (!user || !page) return null;
    
    // Cascade: Admin or Owner
    if (checkIsAdmin(user) || checkIsOwner(user, page)) {
        return children;
    }
    return null;
};

/******************************************************************************
 * CONTRIBUTOR PROTECTION
 * Requires user to be admin, owner, or listed as a contributor
 * Allows: Admins, Owners, Contributors
 ******************************************************************************/
const ContributorProtected = ({ children, page }) => {
    const { user } = useAuth();
    if (!user || !page) return null;
    
    // Cascade: Admin or Owner or Contributor
    if (checkIsAdmin(user) || 
        checkIsOwner(user, page) || 
        checkIsContributor(user, page)) {
        return children;
    }
    return null;
};

/******************************************************************************
 * MEMBER PROTECTION
 * Most permissive level - handles both public and member-restricted content
 * For public content: Shows to everyone
 * For member content: Shows to Admins, Owners, Contributors, Members
 ******************************************************************************/
const MemberProtected = ({ children, page }) => {
    const { user } = useAuth();
    if (!page) return null;

    // Public content is always visible
    if (page.visibility !== 'members') {
        return children;
    }

    // Handle not logged in
    if (!user) {
        return (
            <div className="member-notice">
                {LOGIN_REQUIRED_MESSAGE}
            </div>
        );
    }

    // Cascade: Admin or Owner or Contributor or Member
    if (checkIsAdmin(user) || 
        checkIsOwner(user, page) || 
        checkIsContributor(user, page) || 
        checkIsMember(user, page)) {
        return children;
    }

    return (
        <div className="member-notice">
            {MEMBERS_ONLY_MESSAGE}
        </div>
    );
};

/******************************************************************************
 * COMPONENT EXPORTS
 * Export all protection components for use throughout the application
 ******************************************************************************/
export { 
    Protected, 
    AdminProtected, 
    OwnerProtected, 
    ContributorProtected, 
    MemberProtected 
};