// client/src/pages/user/Profile.js
import React from 'react';
import { UserBlock,FindEmbersCharacters, FriendsList, AchievementBlock } from 'components';

const Profile = () => {
        return (            
             <div className="page-wrapper">
                 <div className="page-container">

                    <div>
                        <h1><UserBlock include={['alias']} /></h1>
                        <hr className='hr-small'></hr>
                    </div>

                    <div className='three-column-fixed'>

                        <div>
                            <h2>Achievements</h2>
                            <AchievementBlock 
                                include={["icon"]}
                                showFloatingText={true}
                                showLocked={true}
                                className='margin-L-Half'
                            />
                        </div>
                        
                        <div>
                            <h2>Friends</h2>
                            <FriendsList
                                showActions={false}
                                display='div'
                            />
                        </div>

                        <div> 
                            <h2>Characters</h2>
                            <FindEmbersCharacters 
                                include="name"
                                ruleset="5th Edition"
                                filterResults="user"
                                linksEnabled={true}                     
                            />
                        </div>
                     </div>
                 </div>
             </div>
        );
     };
     
export default Profile;