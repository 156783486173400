//client/src/components/RecentUpdates.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarkdownRenderer, AdminProtected, Icons } from 'components';
import { useApi } from 'context';

const RecentUpdates = ({ currentPageId }) => {
    const [recentUpdates, setRecentUpdates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const api = useApi();

    const handleCreateUpdate = () => {
        const today = new Date();
        const formattedDate = `${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}-${today.getFullYear().toString().slice(-2)}`;
        
        navigate('/admin/page/create', { 
            state: {
                initialValues: {
                    name: `Update ${formattedDate}`,
                    type: 'Site',
                    subType: 'Update',
                    desc: 'Week of mm/dd-'
                }
            }
        });
    };

    useEffect(() => {
        const fetchRecentUpdates = async () => {
            try {
                const response = await api.get('/api/pages/recent-updates');
                setRecentUpdates(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecentUpdates();
    }, [currentPageId, api]);

    if (loading) return <div>Loading recent updates...</div>;
    if (error) return null;
    if (recentUpdates.length === 0) return null;

    return (
        <div className='left relative'>
            <AdminProtected>
                <button 
                    className="icon-float-right icon i24" 
                    onClick={handleCreateUpdate}
                    title="Create Update"
                >
                    <Icons.CreateIcon />
                </button>
            </AdminProtected>   

            <h3>Recent Updates</h3>
            <div>
                {recentUpdates.map(update => (
                    <div key={update._id}>
                        <div>
                            {update.desc && <MarkdownRenderer content={update.desc} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecentUpdates;