import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../config';
import { MarkdownEditor, DeleteIcon, CreateIcon } from 'components';
import { useFormDirty } from 'hooks';
import { useAuth } from 'context';
import './createRule.css';

function CreateRule() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [error, setError] = useState('');
    const [embersMatches, setEmbersMatches] = useState([]);
    const [searching, setSearching] = useState(false);
    
    // Initial form details
    const initialDetails = [
        { label: 'Type', value: '' },
        { label: '', value: '' },
        { label: '', value: '' }
    ];

    // Main page state
    const [page, setPage] = useState({
        name: '',
        type: 'Rule',
        subType: '',
        desc: '',
        details: initialDetails,
        embersRuleId: null,
        tags: []
    });

    const isFormDirty = useFormDirty(page);

// Add a new state for the tag input
const [newTag, setNewTag] = useState('');

// Add these functions
const handleAddTag = (e) => {
    e.preventDefault(); // Prevent form submission
    if (newTag.trim()) {
        setPage(prev => ({
            ...prev,
            tags: [...prev.tags, newTag.trim()]
        }));
        setNewTag('');
    }
};

const handleRemoveTag = (tagToRemove) => {
    setPage(prev => ({
        ...prev,
        tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
};


    // Available rule types matching EMBERS structure
    const subTypeOptions = [
        'Ability',
        'Character',
        'Equipment',
        'Feat',
        'Magic',
        'Species'
    ];

    // Debounced search effect
    useEffect(() => {
        const timer = setTimeout(() => {
            if (page.name && page.subType) {
                searchEmbersRules(page.name, page.subType);
            }
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, [page.name, page.subType]);

    // Search EMBERS rules
    const searchEmbersRules = async (name, subType) => {
        if (!user?.embersId || !name || !subType) {
            setEmbersMatches([]);
            return;
        }
        
        setSearching(true);
        try {
            // First, check for existing Veylantia rules with the same name
            const existingRules = await axios.get(`${config.serverUrl}/api/pages`, {
                params: {
                    type: 'Rule',
                    name: name
                },
                withCredentials: true
            });
    
            // Get existing rule names
            const existingNames = new Set(existingRules.data.map(rule => 
                rule.name.toLowerCase()
            ));
    
            // Get EMBERS rules
            const response = await axios.get(`${config.serverUrl}/api/embers/rules/search`, {
                params: {
                    userId: user.embersId,
                    name: name,
                    type: subType
                },
                withCredentials: true
            });
    
            // Filter out rules that already exist in Veylantia
            const filteredRules = response.data.filter(rule => 
                !existingNames.has(rule.name.toLowerCase())
            );
    
            setEmbersMatches(filteredRules);
        } catch (err) {
            console.error('Error searching EMBERS rules:', err);
            setEmbersMatches([]);
        } finally {
            setSearching(false);
        }
    };

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPage(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear matches if name field is emptied
        if (name === 'name' && !value) {
            setEmbersMatches([]);
        }
    };

    // Handle detail field changes
    const handleDetailChange = (index, value) => {
        setPage(prev => {
            const newDetails = [...prev.details];
            newDetails[index] = { ...newDetails[index], value };
            return { ...prev, details: newDetails };
        });
    };

    // Handle selection of an EMBERS rule
    const handleEmbersRuleSelect = (rule) => {
        if (rule) {
            setPage(prev => ({
                ...prev,
                embersRuleId: rule._id,
                name: rule.name  // Auto-fill the name
            }));
            // Clear matches after selection
            setEmbersMatches([]);
        }
    };

    // Remove a detail field
    const handleRemoveDetail = (index) => {
        setPage(prev => ({
            ...prev,
            details: prev.details.filter((_, i) => i !== index)
        }));
    };

    const handleCancel = () => {
        navigate(-1);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${config.serverUrl}/api/pages`,
                page,
                { withCredentials: true }
            );
            
            if (response.status === 201) {
                navigate(`/pages/${response.data._id}`);
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while creating the rule');
        }
    };

    return (
        <div className="create-page-container fill75 center">
            <h1>Create Rule</h1>
            
            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}

            <form onSubmit={handleSubmit} className="create-page-form">



                <div className='two-column'>
                {/* Rule Type Selection */}
                <div className="form-row">
                    <select
                        name="subType"
                        value={page.subType}
                        onChange={handleChange}
                        required
                        className="full-width"
                    >
                        <option value="">Select Rule Type</option>
                        {subTypeOptions.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                {/* Rule Name with Autocomplete */}
                <div className="form-row">
                    <div className="autocomplete-container">
                        <input
                            type="text"
                            name="name"
                            value={page.name}
                            onChange={handleChange}
                            required
                            maxLength={100}
                            placeholder="Rule Name"
                            className="full-width"
                        />
                        {searching && <div className="searching-indicator">Searching...</div>}
                        {embersMatches.length > 0 && page.name && (
                            <div className="autocomplete-dropdown">
                                {embersMatches.map(rule => (
                                    <div 
                                        key={rule._id} 
                                        className="autocomplete-item"
                                        onClick={() => handleEmbersRuleSelect(rule)}
                                    >
                                        {rule.name} ({rule.ruleset})
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                </div>



{/* ----------------------------------------------------------------------- */}
{/* Rule Description                                                        */}
{/* ----------------------------------------------------------------------- */}
                {/* <div className="form-row">
                   <MarkdownEditor
                        value={page.desc}
                        onChange={handleChange}
                        name="desc"
                        label="Description"
                    />
                </div>  */}
{/* ----------------------------------------------------------------------- */}

            <div className='two-column'>
{/* ----------------------------------------------------------------------- */}
{/* Details                                                                 */}
{/* ----------------------------------------------------------------------- */}
                <div className='grid-item'>
                    {/* Rule Details */}
                    <div className="details-section">
                        {page.details.map((detail, index) => (
                            <div key={index} className="detail-row">
                                <label>{detail.label}</label>
                                <div className="detail-input-group">
                                    <input
                                        type="text"
                                        value={detail.value}
                                        onChange={(e) => handleDetailChange(index, e.target.value)}
                                        placeholder={`Enter ${detail.label}`}
                                    />

                                    <button
                                        type="button"
                                        className="icon-button"
                                        onClick={() => handleRemoveDetail(index)}
                                        aria-label={`Remove ${detail.label}`}
                                    >
                                        <DeleteIcon />
                                    </button>


                                </div>
                            </div>
                        ))}
                    </div>
                </div>
{/* ----------------------------------------------------------------------- */}


{/* ----------------------------------------------------------------------- */}
{/* Tags                                                                    */}
{/* ----------------------------------------------------------------------- */}
                <div className='grid-item'>
                    <div className="form-row">
                        <div className="tags-section">
                            <div className="tag-input-row">
                                <input
                                    type="text"
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    placeholder="Add tag..."
                                    className="tag-input"
                                />
                                <button 
                                    type="button"
                                    onClick={handleAddTag}
                                    className="icon-button"
                                >
                                    <CreateIcon /> 
                                </button>
                            </div>
                            <div className="tags-list">
                                {page.tags.map((tag, index) => (
                                    <span key={index} className="tag">
                                        {tag}
                                        <button
                                            onClick={() => handleRemoveTag(tag)}
                                            className="icon-button"
                                            type="button"
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
{/* ----------------------------------------------------------------------- */}


{/* ----------------------------------------------------------------------- */}
{/* Cancel/Save Buttons                                                     */}
{/* ----------------------------------------------------------------------- */}
                {/* Form Actions */}
                <div className="floating-action-button-container">
                    <button 
                        type="button" 
                        className="floating-action-button secondary" 
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className={`floating-action-button ${!isFormDirty ? 'secondary' : ''}`}
                        disabled={!isFormDirty}
                    >
                        Save
                    </button>
                </div>
{/* ----------------------------------------------------------------------- */}


            </form>
        </div>
    );
}

export default CreateRule;