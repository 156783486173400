//client/src/hooks/useFormDirty.js
import { useState, useEffect } from 'react';

export const useFormDirty = (currentData) => {
    const [originalData, setOriginalData] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (currentData && !originalData) {
            setOriginalData(JSON.stringify(currentData));
        }
    }, [currentData]);

    useEffect(() => {
        if (!currentData || !originalData) {
            setIsDirty(false);
            return;
        }
        setIsDirty(JSON.stringify(currentData) !== originalData);
    }, [currentData, originalData]);

    return isDirty;
};