import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../config';
import { MarkdownEditor, DeleteIcon } from 'components';
import { useFormDirty } from 'hooks';
import './createNPC.css'

function CreateNPC() {
    const navigate = useNavigate();
    const initialDetails = [
        { label: 'Places', value: '' },
        { label: 'Factions', value: '' },
        { label: 'Titles', value: '' },
        { label: 'Status', value: '' }
    ];

    const [error, setError] = useState('');
    const [page, setPage] = useState({
        name: '',
        type: 'Person',
        subType: 'NPC',
        desc: '',
        details: initialDetails
    });
    const isFormDirty = useFormDirty(page);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPage(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDetailChange = (index, value) => {
        setPage(prev => {
            const newDetails = [...prev.details];
            newDetails[index] = { ...newDetails[index], value };
            return { ...prev, details: newDetails };
        });
    };

    const removeDetail = (index) => {
        setPage(prev => ({
            ...prev,
            details: prev.details.filter((_, i) => i !== index)
        }));
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const processedPage = {
                ...page,
                pageImg: `/site/world/people/${page.name.toLowerCase().replace(/\s+/g, '_')}.jpg`
            };
            
            const response = await axios.post(
                `${config.serverUrl}/api/pages`,
                processedPage,
                { withCredentials: true }
            );
            
            if (response.status === 201) {
                navigate(`/pages/${response.data._id}`);
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while creating the NPC');
        }
    };

    return (
        <div className="create-page-container fill75 center">
            <h1>Create NPC</h1>
            
            {error && (
                <div className="error-message">
                    {error}
                </div>
            )}

            <form onSubmit={handleSubmit} className="create-page-form">
                <div className="form-row">
                    <input
                        type="text"
                        name="name"
                        value={page.name}
                        onChange={handleChange}
                        required
                        maxLength={100}
                        placeholder="NPC Name"
                        className="full-width"
                    />
                </div>

                <div className="form-row">
                    <MarkdownEditor
                        value={page.desc}
                        onChange={handleChange}
                        name="desc"
                        label="Description"
                    />
                </div>

                <div className="details-section">
                    {page.details.map((detail, index) => (
                        <div key={index} className="detail-row">
                            <label>{detail.label}</label>
                            <div className="detail-input-group">
                                <input
                                    type="text"
                                    value={detail.value}
                                    onChange={(e) => handleDetailChange(index, e.target.value)}
                                    placeholder={`Enter ${detail.label}`}
                                />
                                <button
                                    type="button"
                                    className="icon-button"
                                    onClick={() => removeDetail(index)}
                                    aria-label={`Remove ${detail.label}`}
                                >
                                    <DeleteIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="floating-action-button-container">
                    <button 
                        type="button" 
                        className="floating-action-button secondary" 
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        className={`floating-action-button ${!isFormDirty ? 'secondary' : ''}`}
                        disabled={!isFormDirty}
                    >
                        Create NPC
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreateNPC;