// client/src/components/tools/PageTabs.js
import React, { useState } from 'react';
import '../../styles/components/pageTabs.css';

/******************************************************************************
 * PAGE TAB
 * Individual tab component that wraps its content
 ******************************************************************************/
export const PageTab = ({ title, children }) => {
  return children;
};

/******************************************************************************
 * PAGE TAB CONTAINER
 * Manages tab state and renders tab navigation
 * @param {Object} props
 * @param {number} [props.defaultActiveTab=0] - Index of the default active tab
 ******************************************************************************/
export const PageTabContainer = ({ children, defaultActiveTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  // Helper function to evaluate if a child should be rendered
  const evaluateChild = (child) => {
    if (!React.isValidElement(child)) return null;

    // If it's a protection component
    if (child.type.name?.includes('Protected')) {
      // If it returns null, the user doesn't have access
      const rendered = child.type(child.props);
      if (!rendered) return null;
      
      // If it's wrapping a PageTab, return the PageTab
      if (React.isValidElement(child.props.children) && 
          child.props.children.type === PageTab) {
        return child;
      }
    }
    
    // If it's a direct PageTab
    if (child.type === PageTab) {
      return child;
    }

    return null;
  };

  // Filter and evaluate children
  const validChildren = React.Children.toArray(children)
    .map(evaluateChild)
    .filter(Boolean);

  // If there are no visible tabs, render nothing
  if (validChildren.length === 0) return null;

  // Ensure active tab is valid
  if (activeTab >= validChildren.length) {
    setActiveTab(0);
  } else if (activeTab < 0) {
    setActiveTab(0);
  }

  // Get titles from the PageTab components
  const titles = validChildren.map(child => {
    if (child.type === PageTab) {
      return child.props.title;
    }
    // For protected tabs, get the title from the wrapped PageTab
    return child.props.children.props.title;
  });

  return (
    <div className="tabs-container">
      <div className="tabs">
        {titles.map((title, index) => (
          <button 
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {title}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {validChildren[activeTab]}
      </div>
    </div>
  );
};