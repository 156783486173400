// client/src/components/user/friends/FriendRequests.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FriendDisplay, Protected, FriendRequestActions } from 'components';

const FriendRequests = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        try {
            const response = await axios.get('/api/user/friends/requests');
            setRequests(response.data);
            setError('');
        } catch (err) {
            setError('Failed to load friend requests');
        } finally {
            setLoading(false);
        }
    };

    const handleAccept = async (requestId) => {
        try {
            await axios.post(`/api/user/friends/accept/${requestId}`);
            setRequests(requests.filter(req => req._id !== requestId));
        } catch (err) {
            setError('Failed to accept request');
        }
    };

    const handleReject = async (requestId) => {
        try {
            await axios.post(`/api/user/friends/reject/${requestId}`);
            setRequests(requests.filter(req => req._id !== requestId));
        } catch (err) {
            setError('Failed to reject request');
        }
    };

    if (loading) return <div>Loading requests...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>

{requests.map(request => (
    <div key={request._id} className='indent' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <FriendDisplay 
            friend={request.from} 
            showAlias={true} 
            showPicture={false}
            showName={false}
        />
        <FriendRequestActions
            requestId={request._id}
            onAccept={() => setRequests(requests.filter(req => req._id !== request._id))}
            onReject={() => setRequests(requests.filter(req => req._id !== request._id))}
        />
    </div>
))}



        </div>
    );
};

export default FriendRequests;