// src/components/Login/Login.js
import React from 'react';
import './login.css';

const Login = () => {
  const handleGoogleLogin = () => {
    // In development, use the full URL
    // In production, use relative path since it's served from the same domain
    const authUrl = process.env.NODE_ENV === 'development' 
      ? `${process.env.REACT_APP_API_URL}/auth/google`
      : '/auth/google';
      
    window.location.href = authUrl;
  };

  return (
    <button 
      onClick={handleGoogleLogin}
      className="login-button"
    >
      Login with Google
    </button>
  );
};

export default Login;