// client/src/components/admin/BroadcastWrapper.js
import React from 'react';
import { useApi } from 'context';

const BroadcastWrapper = ({ 
    children, 
    shouldBroadcast,
    formatMessage,
    onSuccess,
    onError 
}) => {
    
    const api = useApi();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send original form submission
            const response = await onSuccess(e);
            
            // Check if we should broadcast
            if (shouldBroadcast(response.data)) {
                const message = formatMessage(response.data);
                await api.post('/api/admin/messages/broadcast', { message });
            }

            return response;
        } catch (err) {
            onError?.(err);
            throw err;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {children}
        </form>
    );
};

export default BroadcastWrapper;