// client/src/components/pages/lists/CharacterList.js
import React from 'react';
import { EmbersBasePage } from 'components';

const CharacterList = (props) => (
  <EmbersBasePage
    type="characters"
    ruleset="5th Edition" 
    displayFields={['name']}
    className="character-list"
    loadingMessage="Loading characters..."
    emptyMessage="No characters found"
    render={(content) => (
      <div className="page-content">
        <h1>Characters</h1>
        {content}
      </div>
    )}
    {...props}
  />
);

export default CharacterList;