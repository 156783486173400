// client/src/components/embers-vault/search/FindEmbersChar.js
import React, { useState, useEffect } from 'react';
import { useApi } from 'context';
import { useAuth } from 'context';

/******************************************************************************
 * PROP TYPES DEFINITION
 ******************************************************************************/
const defaultProps = {
  include: '',        // Comma-separated list of fields to display
  exclude: '',        // Comma-separated list of fields to exclude
  className: '',      // CSS class for styling
  ruleset: '',        // Filter characters by ruleset
  groupBy: '',        // Fields to group by (e.g., "user,ruleset")
  showGroupHeading: true,  // Whether to show group headers
  groupHeadingLevels: ['h2', 'h3'],  // HTML heading levels for grouping levels
  filterResults: 'user', // user, friends, or user-friends
  linksEnabled: false 
};

/******************************************************************************
 * UTILITY FUNCTIONS
 ******************************************************************************/
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((current, key) => current?.[key], obj);
};

const getFieldsToDisplay = (character, include, exclude) => {
  if (include) {
    const fields = include.split(',').map(f => f.trim());
    return fields.map(field => ({
      key: field,
      value: getNestedValue(character, field)
    })).filter(({value}) => value !== undefined);
  }
  
  return Object.entries(character)
    .filter(([key]) => key !== '_id' && key !== '__v')
    .filter(([key]) => !exclude || !exclude.split(',').map(f => f.trim()).includes(key))
    .map(([key, value]) => ({key, value}));
};

/******************************************************************************
 * MAIN COMPONENT
 ******************************************************************************/
const FindEmbersCharacters = (props) => {
  const api = useApi();
  const [characters, setCharacters] = useState([]);
  const [userAliasMap, setUserAliasMap] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  
  const {
    include,
    exclude,
    className,
    ruleset,
    groupBy,
    showGroupHeading,
    groupHeadingLevels,
    filterResults,
    linksEnabled 
  } = { ...defaultProps, ...props };

  // Fetch characters and user data
  useEffect(() => {
    const fetchData = async () => {
      if (!user?.embersId) {
        setError('No Embers Vault account linked');
        setLoading(false);
        return;
      }

      try {
        // First get user aliases
        const userResponse = await api.get(
          `/api/user/friends-with-embers`,
          { withCredentials: true }
        );

        // Create alias mapping
        const aliasMap = {
          [userResponse.data.user.embersId]: userResponse.data.user.alias,
          ...Object.fromEntries(
            userResponse.data.friends
              .filter(f => f.embersId)
              .map(f => [f.embersId, f.alias])
          )
        };
        setUserAliasMap(aliasMap);

        // Then fetch characters
        const queryParams = new URLSearchParams();
        if (ruleset) queryParams.append('ruleset', ruleset);
        queryParams.append('filterResults', filterResults);

        const charactersResponse = await api.get(
          `/api/embers/characters/${user.embersId}?${queryParams}`,
          { withCredentials: true }
        );

        // Add alias to each character
        const enhancedCharacters = charactersResponse.data.map(char => ({
          ...char,
          ownerAlias: aliasMap[char._user] || 'Unknown User'
        }));

        setCharacters(enhancedCharacters);
        setError(null);
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, ruleset, filterResults]);

  // Group characters function
  const groupCharacters = (chars, grouping) => {
    if (!grouping) return { ungrouped: chars };

    const groupLevels = grouping.split(',').map(g => g.trim());

    const groupRecursively = (items, level = 0) => {
      if (level >= groupLevels.length) return items;

      const groups = {};
      items.forEach(item => {
        const key = groupLevels[level] === 'user' 
          ? item.ownerAlias 
          : (item[groupLevels[level]] || 'Other');

        if (!groups[key]) groups[key] = [];
        groups[key].push(item);
      });

      if (level < groupLevels.length - 1) {
        Object.keys(groups).forEach(key => {
          groups[key] = groupRecursively(groups[key], level + 1);
        });
      } else {
        Object.keys(groups).forEach(key => {
          groups[key].sort((a, b) => a.name?.localeCompare(b.name || ''));
        });
      }

      return groups;
    };

    return groupRecursively(chars);
  };

  // Create Embers-Vault link
  const renderValue = (key, value, character) => {
    if (key === 'name' && linksEnabled && character._id) {
      return (
        <a 
          href={`https://embersrpgvault.com/characters/${character._id}/sheet`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }
    return value;
  };

  // Render groups recursively
  const renderGroups = (groups, level = 0) => {
    if (Array.isArray(groups)) {
      return (
        <ul>
          {groups.map(character => (
            <div key={character._id}>
              {getFieldsToDisplay(character, include, exclude).map(({key, value}) => (
                <div key={key}>
                  {renderValue(key, value, character)}
                </div>
              ))}
            </div>
          ))}
        </ul>
      );
    }

    

    const HeadingTag = groupHeadingLevels[level] || 'div';

    return (
      <>
        {Object.entries(groups).map(([groupName, groupContent]) => (
          <div key={groupName} className={`character-group level-${level}`}>
            {showGroupHeading && groupName !== 'ungrouped' && (
              <HeadingTag className={`group-heading level-${level}`}>
                {groupName}
              </HeadingTag>
            )}
            {renderGroups(groupContent, level + 1)}
          </div>
        ))}
      </>
    );
  };

  if (loading) {
    return <div className={className}>Loading characters...</div>;
  }

  if (error) {
    return <div className={className}>{error}</div>;
  }

  const grouped = groupCharacters(characters, groupBy);

  return (
    <div className={className}>
      {renderGroups(grouped)}
    </div>
  );
};

export default FindEmbersCharacters;