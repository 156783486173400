// client/src/components/tools/MarkdownEditor.js 
import React from 'react';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useTheme, useMarkdown } from 'hooks';
import '../../styles/components/markdownEditor.css'

const MarkdownEditor = ({ value, onChange, label, required = false, name = 'content' }) => {
  const { theme } = useTheme();
  const { renderMarkdown } = useMarkdown();

  const handleEditorChange = ({ text }) => {
    onChange({
      target: {
        name,
        value: text
      }
    });
  };

  return (
    <div className="form-group" data-theme={theme}>
      {label && (
        <label>
          {label} {required && '*'}
        </label>
      )}
      <MdEditor
        value={value}
        onChange={handleEditorChange}
        renderHTML={renderMarkdown}
        view={{ menu: true, md: true, html: false }} 
        canView={{
          menu: true,
          md: true,
          html: false,
          fullScreen: false,
          hideMenu: false,
        }}
        className="custom-md-editor"
      />
    </div>
  );
};

export default MarkdownEditor;