// client/src/components/pages/lists/index.js
import React from 'react';
import ContentList from './ContentList';
import CharacterList from './CharacterList';

// Define all components first
const CharacterRulesList = (props) => (
  <ContentList type="Rule" subType="Character" {...props} />
);

const SpeciesRulesList = (props) => (
  <ContentList type="Rule" subType="Species" {...props} />
);

const FeatRulesList = (props) => (
  <ContentList type="Rule" subType="Feat" {...props} />
);

const GearRulesList = (props) => (
  <ContentList type="Rule" subType="Equipment" {...props} />
);

const MagicRulesList = (props) => (
  <ContentList type="Rule" subType="Magic" {...props} />
);

const MiscRulesList = (props) => (
  <ContentList type="Rule" subType="Misc" {...props} />
);

const AbilityRulesList = (props) => (
  <ContentList type="Rule" subType="Ability" {...props} />
);

const HistoryStories = (props) => (
  <ContentList type="Fiction" subType="History" {...props} />
);

const NationsList = (props) => (
  <ContentList type="Place" subType="Nation" {...props} />
);

const ShortStoryList = (props) => (
  <ContentList 
    type="Fiction" 
    subType="Short Story" 
    sortOrder="-createdAt"  // Add this prop
    {...props} 
  />
);

const EmbersCharacterList = (props) => (
  <CharacterList {...props} />
 );
 

// Then export them all
export {
  CharacterRulesList,
  SpeciesRulesList,
  FeatRulesList,
  GearRulesList,
  MagicRulesList,
  MiscRulesList,
  AbilityRulesList,
  HistoryStories,
  NationsList,
  ShortStoryList,
  EmbersCharacterList
};