import React, { useState, useEffect } from 'react';
import { useApi } from 'context';
import { PageRoute } from 'components';

function PageList() {
    const api = useApi();
    const [pages, setPages] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await api.get(
                    `/api/pages`,
                    { withCredentials: true }
                );
                // Sort pages alphabetically by name
                const sortedPages = response.data.sort((a, b) => 
                    a.name.localeCompare(b.name)
                );
                setPages(sortedPages);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch pages');
            } finally {
                setLoading(false);
            }
        };

        fetchPages();
    }, []);

    if (loading) return <div>Loading pages...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="page-list-container">
            <h1>Pages</h1>
            {pages.length === 0 ? (
                <div className="no-pages">No pages found</div>
            ) : (
                <div className=" center fill  left slim-and-trim column-4">
                    {pages.map(page => (
                        <div key={page._id}>
                            <PageRoute page={page}>
                                {page.name}
                            </PageRoute>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default PageList;