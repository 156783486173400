//client/src/components/pages/PageRoute.js
import React from 'react';
import { Link } from 'react-router-dom';

const PageRoute = ({ page, children }) => {
  const getViewPath = (page) => {
    // Check if this page has a linkoverride tag
    if (page.tags?.includes('linkoverride')) {
      // If it's an override, use the link's url directly without appending the ID
      return page.link?.url || '/pages';
    }
    
    // Original behavior for normal pages
    if (page.link?.url) {
      return `${page.link.url}/${page._id}`;
    }
    return `/pages/${page._id}`;
  };

  return (
    <Link to={getViewPath(page)}>
      {children}
    </Link>
  );
};

export default PageRoute;