// client/src/components/user/friends/FriendDisplay.js
import React from 'react';
import { DeleteIcon, CheckIcon, AddFriendIcon, Confirmation } from 'components';

const FriendDisplay = ({ 
    friend, 
    showAlias = true,
    showPicture = true,
    showName = true,
    showActions = true,
    showAddFriend = false,    // For adding friend button
    showAccept = false,       // For accepting requests
    showReject = false,       // For rejecting requests
    showRemove = false,       // Added for remove friend button
    onAddFriend,             // For adding friend
    onAccept,                // For accepting requests
    onReject,                // For rejecting requests
    onRemove                 // Added for removing friend
}) => {
    return (
        <>
            {showPicture && friend.picture && (
                <img 
                    src={friend.picture} 
                    alt={friend.name} 
                    className="friend-avatar"
                />
            )}
            {showName && <span>{friend.name}</span>}
            {showAlias && <span>{friend.alias}</span>}
            {showActions && (
                <>
                    {showAddFriend && (
                        <button onClick={() => onAddFriend(friend._id)} className="inline margin-LR1 icon i24">
                            <AddFriendIcon/>
                        </button>
                    )}
                    {showAccept && (
                        <button onClick={() => onAccept(friend._id)} className="inline icon i24">
                            <CheckIcon />
                        </button>
                    )}
                    {showReject && (
                        <button onClick={() => onReject(friend._id)} className="inline icon i24">
                            <DeleteIcon />
                        </button>
                    )}
                    {showRemove && onRemove && (
    <Confirmation
    message="Are you sure you want to remove this friend?"
    onConfirm={() => onRemove(friend._id)}
>

                        <button onClick={() => onRemove(friend._id)} className="inline icon i24">
                            <DeleteIcon />
                        </button>
                        </Confirmation>
                    )}
                </>
            )}
        </>
    );
};

export default FriendDisplay;