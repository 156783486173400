//client/src/pages/views/FeatView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from 'context';
import { DefaultBlock, SectionBlock, DetailsBlock, AdminEditButton } from 'components';
import '../../styles/pages/views/featView.css'

function FeatView() {
    const { id } = useParams();
    const api = useApi();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await api.get(`/api/pages/${id}`);
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch equipment');
            } finally {
                setLoading(false);
            }
        };        
        fetchPage();
    }, [id, api]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No feat found</div>;

    return (
        <div className="feat-view">

            <AdminEditButton id={id} />

{/* ------------------------------------------------------------------ */}
{/* Page Content                                                       */}
{/* ------------------------------------------------------------------ */}

            <div>
                <DefaultBlock 
                    page={page} 
                    exclude={['secName', 'secDesc', 'desc','pageImg']}
                    className="left" 
                />
                <hr/>
            </div>

            <div className='detail-block-leading-subtext left'>
                <DetailsBlock 
                    details={page.details} 
                    excludeLabels={['']} 
                    detailLabels={['Type']}
                    className="details-block-type"
                    hideLabels={true}
                    inline={true}
                    noDefaultStyles={true}
                /> <span> Feat</span>

{page.details?.find(detail => detail.label === 'Prerequisites')?.value && (
    <>

                <span> (</span>
                <DetailsBlock 
                    details={page.details} 
                    excludeLabels={['']} 
                    detailLabels={['Prerequisites']}
                    className="details-block-prereq"
                    inline={true}
                    noDefaultStyles={true}
                />
                <span>)</span>
                </>
               )}



            </div>

            {page.pageImg && (
            <div className="img-div">
                <DefaultBlock 
                    page={page} 
                    include={['pageImg']}
                    className="img-right img-circle img-300"
                    />
            </div>
            )}


            <div className="feat-header">
                <DefaultBlock 
                    page={page} 
                    include={['desc']}
                    className=""
                    />
            </div>
            
            <div className="feat-content left">
                <SectionBlock 
                    sections={page.sections}
                    noDefaultStyles={true}
                    />
            </div>



        </div>
    );
}

export default FeatView;