//client/src/components/tools/CollapsiblePanel.js
import React, { useState } from 'react';
import { Icons } from 'components';
import '../../styles/components/collapsiblePanel.css'

const CollapsiblePanel = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="collapsible-panel">
 <button 
    className="collapsible-header" 
    onClick={() => setIsOpen(!isOpen)}
    type="button"
    aria-expanded={isOpen}
>
    {isOpen ? <Icons.ChevronDownIcon /> : <Icons.ChevronRightIcon />}
    <span>{title}</span>
</button>
      {isOpen && (
        <div className="collapsible-content">
          {children}
        </div>
      )}
    </div>
  );
};

export default CollapsiblePanel;
