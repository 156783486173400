//client/src/components/pages/building-blocks/DetailsBlock.js
import React from 'react';

const DetailsBlock = ({ 
  details, 
  className = '', 
  excludeLabels = [],
  includeLabels = [],  
  detailLabels = [],
  hideLabels = false, 
  hideValues = false,
  inline = false,
  noDefaultStyles = false,
  wrapInParens = false    
}) => {
  if (!details?.length) return null;

  let filteredDetails = details;

  // First apply includeLabels if provided
  if (includeLabels.length > 0) {
    filteredDetails = filteredDetails.filter(detail => 
      includeLabels.includes(detail.label)
    );
  }

  // Then apply detailLabels if provided
  if (detailLabels.length > 0) {
    filteredDetails = filteredDetails.filter(detail => 
      detailLabels.includes(detail.label)
    );
  }

  // Finally apply excludeLabels
  filteredDetails = filteredDetails.filter(detail => 
    !excludeLabels.includes(detail.label)
  );

  if (!filteredDetails.length) return null;

// Create the inner content without parentheses
const innerContent = (
  <div className={`${noDefaultStyles ? '' : 'page-details'} ${className}`} style={{ display: inline ? 'inline' : 'block' }}>
    {filteredDetails.map((detail, index) => (
      <div key={index} className={noDefaultStyles ? '' : 'detail-item'} style={{ display: inline ? 'inline' : 'block' }}>
        {!hideLabels && (
          <label className={noDefaultStyles ? '' : 'lead-text'}>{detail.label}: </label>
        )}
        {!hideValues && (
          <span>{detail.value}</span>
        )}
      </div>
    ))}
  </div>
);

// If we're wrapping in parentheses, put them outside the content that will be transformed
return wrapInParens ? (
  <span style={{ display: 'inline' }}>
    <span style={{ display: 'inline' }}>{" ("}</span>
    {innerContent}
    <span style={{ display: 'inline' }}>{")"}</span>
  </span>
) : innerContent;
};

export default DetailsBlock;