import { useEffect, useState } from 'react';

export const usePageStyles = (pageType, pageSubType) => {
    const [isStylesLoaded, setIsStylesLoaded] = useState(false);

    useEffect(() => {
        setIsStylesLoaded(false); // Reset when type/subType changes
        
        const styleExists = async (filename) => {
            try {
                const response = await fetch(`/styles/pages/${filename}`);
                return response.ok;
            } catch (error) {
                console.error(`Error checking stylesheet ${filename}:`, error);
                return false;
            }
        };

        const loadPageStyles = async () => {
            document.querySelectorAll('link[data-page-style="true"]')
                .forEach(link => link.remove());

            if (!pageType) {
                setIsStylesLoaded(true);
                return;
            }

            const type = pageType.toLowerCase();
            const subType = pageSubType?.toLowerCase();

            const styleChecks = [
                { file: `${type}.css`, exists: await styleExists(`${type}.css`) },
                subType && { file: `${subType}.css`, exists: await styleExists(`${subType}.css`) }
            ].filter(Boolean);

            // Load stylesheets and wait for them to be ready
            const loadPromises = styleChecks.map(async (style) => {
                if (style.exists) {
                    return new Promise((resolve) => {
                        const styleLink = document.createElement('link');
                        styleLink.rel = 'stylesheet';
                        styleLink.href = `/styles/pages/${style.file}`;
                        styleLink.setAttribute('data-page-style', 'true');
                        styleLink.onload = resolve;
                        document.head.appendChild(styleLink);
                    });
                }
                return Promise.resolve();
            });

            await Promise.all(loadPromises);
            setIsStylesLoaded(true);
        };

        loadPageStyles();

        return () => {
            document.querySelectorAll('link[data-page-style="true"]')
                .forEach(link => link.remove());
        };
    }, [pageType, pageSubType]);

    return isStylesLoaded;
};