// client/src/pages/views/AllPeopleView.js
import React, { useState, useEffect } from 'react';
import { useApi } from 'context';
import { DefaultBlock, AlphabeticalList, DetailsBlock, LinkTransformation } from 'components';

const AllPeopleView = () => {
  const api = useApi();  
 const [people, setPeople] = useState([]);
 const [error, setError] = useState('');
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   const fetchData = async () => {
     try {
       const response = await api.get(
         `/api/pages?type=Person&subType=NPC&sort=name`,
         { withCredentials: true }
       );
       setPeople(response.data);
     } catch (err) {
       setError(err.response?.data?.error || 'Failed to fetch NPCs');
     } finally {
       setLoading(false);
     }
   };
   fetchData();
 }, [api]);

 // This controls how each individual person card looks
 const renderPerson = (person) => (

          <div className="npc-item" key={person._id}>
            <div className='card300'>

              {person.pageImg && (
                <DefaultBlock 
                  page={person}
                  include={['pageImg']} 
                  className='img-circle img-300'          	
                />
              )}

              <DefaultBlock 
                page={person} 
                include={["name"]}
                titleLevel="h3"
              />

              <LinkTransformation>
              <DetailsBlock 
                details={person.details}
                includeLabels={["Places"]}
                hideLabels={true}
                inline={true}
              />
              </LinkTransformation>

              {person.details?.some(detail => detail.label === "Factions") && (
              <>


<LinkTransformation wrapInParens={true}>
<DetailsBlock 
                  details={person.details}
                  includeLabels={["Factions"]}
                  hideLabels={true}
                  inline={true}
                  wrapInParens={true}
                /> 
                </LinkTransformation>
              </>
              )}

              <DefaultBlock 
                page={person} 
                include={["desc"]}
              />

            </div> {/* end card300 div */}
          </div> ///end npc-item div 
 );

 if (loading) return <div>Loading...</div>;
 if (error) return <div className="error-message">{error}</div>;
 if (!people || people.length === 0) return <div>No NPCs found</div>;

 // Main return - The grid layout for the 4 columns should be applied 
 // to the 'letter-items' class in your CSS
 return (
   <div className="people-list-view">
     <h1>Notable People</h1>
     <hr className="hr-small"/>
     
     <AlphabeticalList
  items={people}
  renderItem={renderPerson}
  renderHeader={letter => (
    <div className="letter-group">
      <h2 className="letter-header">{letter}</h2>
      <hr className="hr-medium"/>
    </div>
  )}
  renderFooter={letter => (
    <div className="letter-footer">
      <hr className="hr-small"/>
    </div>
  )}
  itemsContainerClassName="three-column" // Your custom class for this specific view
/>

   </div>
 );
};

export default AllPeopleView;