// components/messages/ViewMessages.js
import React, { useState, useEffect } from 'react';
import { useApi } from 'context';
import { FriendRequestActions, MessageContent } from 'components';
import './viewMessages.css'

const ViewMessages = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [view, setView] = useState('all'); // 'all', 'unread', 'broadcast'
    const api = useApi();

    useEffect(() => {
        fetchMessages();
    }, [view, api]);

    const fetchMessages = async () => {
        try {
            let url = '/api/messages';
            if (view === 'unread') url += '?unread=true';
            if (view === 'broadcast') url += '?type=broadcast';
            
            const response = await api.get(url);
            setMessages(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to load messages');
            setLoading(false);
        }
    };

    const handleMarkAsRead = async (messageId) => {
        try {
            await api.put(`/api/messages/${messageId}/read`);
            // Update local message state
            setMessages(messages.map(msg => 
                msg._id === messageId ? {...msg, isRead: true} : msg
            ));
            // Trigger a refetch of unread messages count globally
            window.dispatchEvent(new Event('messagesUpdated'));
        } catch (err) {
            console.error('Failed to mark message as read:', err);
        }
    };

    const handleDelete = async (messageId) => {
        try {
            await api.delete(`/api/messages/${messageId}`);
            setMessages(messages.filter(msg => msg._id !== messageId));
        } catch (err) {
            console.error('Failed to delete message:', err);
        }
    };

    if (loading) return <div>Loading messages...</div>;
    if (error) return <div>{error}</div>;



    return (
        <div className="message-list">
            <div className="message-filters">
                <button 
                    className={view === 'all' ? 'active' : ''} 
                    onClick={() => setView('all')}
                >
                    All Messages
                </button>
                <button 
                    className={view === 'unread' ? 'active' : ''} 
                    onClick={() => setView('unread')}
                >
                    Unread
                </button>
                <button 
                    className={view === 'broadcast' ? 'active' : ''} 
                    onClick={() => setView('broadcast')}
                >
                    Broadcasts
                </button>
            </div>



            <div className="messages">
                {messages.length === 0 ? (
                    <div className="no-messages">No messages to display</div>
                ) : (
                    messages.map(message => (
                        <div 
                            key={message._id} 
                            className={`message ${!message.isRead ? 'unread' : ''}`}
                        >






                            <div className="message-header">
                                <span className="message-type">
                                    {message.type === 'broadcast' ? 'Broadcast' : 'Message'}
                                </span>
                                <span className="message-date">
                                    {new Date(message.createdAt).toLocaleDateString()}
                                </span>
                                {!message.isRead && (
                                    <button 
                                        className="mark-read"
                                        onClick={() => handleMarkAsRead(message._id)}
                                    >
                                        Mark as Read
                                    </button>
                                )}
                                <button 
                                    className="delete"
                                    onClick={() => handleDelete(message._id)}
                                >
                                    Delete
                                </button>
                            </div>



                            <div className="message-content">
                            <MessageContent message={message} />
                            </div>
                        </div>
                    ))
                )}
            </div>




            
        </div>
    );
};

export default ViewMessages;