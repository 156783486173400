//client/src/hooks/useTheme.js

import { useState, useEffect } from 'react';
import { useAuth } from 'context';
import { applyTheme } from 'styles';

export const useTheme = () => {
  const { user } = useAuth();
  const [theme, setTheme] = useState('light');

  // Initial load of user preference
  useEffect(() => {
    const fetchUserPreference = async () => {
      if (user) {
        try {
          const apiUrl = process.env.NODE_ENV === 'development' 
            ? `${process.env.REACT_APP_API_URL}/api/user/settings`
            : '/api/user/settings';

          const response = await fetch(apiUrl, {
            credentials: 'include'
          });
          
          if (!response.ok) throw new Error('Failed to fetch settings');
          
          const data = await response.json();
          const newTheme = data.darkMode ? 'dark' : 'light';
          setTheme(newTheme);
          applyTheme(newTheme);
        } catch (error) {
          console.error('Error fetching user theme preference:', error);
        }
      }
    };

    fetchUserPreference();
  }, [user]);

  const toggleTheme = async () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    applyTheme(newTheme);

    if (user) {
      try {
        const apiUrl = process.env.NODE_ENV === 'development' 
          ? `${process.env.REACT_APP_API_URL}/api/user/settings`
          : '/api/user/settings';

        const response = await fetch(apiUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ darkMode: newTheme === 'dark' })
        });

        if (!response.ok) throw new Error('Failed to update theme preference');
      } catch (error) {
        console.error('Error updating theme preference:', error);
      }
    }
  };

  return { theme, toggleTheme };
};