// client/src/components/tools/Confirmation.js
import React, { useState } from 'react';

const Confirmation = ({ 
   children,          
   message = "Are you sure?",          
   onConfirm,        
   yesText = 'Yes',  
   noText = 'No'     
}) => {
   const [showPopup, setShowPopup] = useState(false);

   const handleClick = (e) => {
       e.preventDefault(); 
       e.stopPropagation();
       setShowPopup(true);
   };

   const handleYes = () => {
       onConfirm();
       setShowPopup(false);
   };

   const handleNo = () => {
       setShowPopup(false);
   };

   const triggerElement = React.cloneElement(children, {
       onClick: handleClick
   });

   return (
       <>
           {triggerElement}
           
           {showPopup && (
               <div 
                   className="modal-overlay"
                   onClick={handleNo}
                   style={{
                       position: 'fixed',
                       top: 0,
                       left: 0,
                       right: 0,
                       bottom: 0,
                       backgroundColor: 'rgba(0, 0, 0, 0.5)',
                       display: 'flex',
                       justifyContent: 'center',
                       alignItems: 'center',
                       zIndex: 1000
                   }}
               >
                   <div 
                       className="modal-content"
                       onClick={e => e.stopPropagation()}
                       style={{
                           background: 'white',
                           padding: '20px'
                       }}
                   >
                       <p>{message}</p>
                       <div>
                           <button onClick={handleYes}>{yesText}</button>
                           <button onClick={handleNo}>{noText}</button>
                       </div>
                   </div>
               </div>
           )}
       </>
   );
};

export default Confirmation;