// client/src/components/user/UserSearch.js
import React, { useState } from 'react';
import axios from 'axios';
import { PeopleIcon } from 'components';
import config from '../../config';
import './userSearch.css';

/******************************************************************************
 * USER SEARCH COMPONENT
 * Provides user search functionality with optional friends-only filtering
 * @param {Object} props
 * @param {Function} props.onUserSelect - Callback when user is selected
 * @param {string} [props.buttonClassName="icon i24"] - CSS class for button
 * @param {string} [props.popupAlignment="right"] - Popup alignment
 * @param {boolean} [props.friendsOnly=false] - Limit search to friends only
 ******************************************************************************/
const UserSearch = ({ 
  onUserSelect,
  buttonClassName = "icon i24",
  popupAlignment = "right",
  friendsOnly = false
}) => {
  const [showUserSearch, setShowUserSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = async (term) => {
    setSearchTerm(term);
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const url = friendsOnly
        ? `${config.serverUrl}/api/user/search/friends?query=${encodeURIComponent(term)}`
        : `${config.serverUrl}/api/user/search?query=${encodeURIComponent(term)}`;

      const response = await axios.get(url, { withCredentials: true });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Search failed:', error);
    }
  };

  const handleUserSelect = (user) => {
    onUserSelect(user);
    setShowUserSearch(false);
    setSearchResults([]);
    setSearchTerm('');
  };

  return (
    <div className="user-search-container">
      <button 
        className={buttonClassName}
        onClick={() => setShowUserSearch(!showUserSearch)}
      >
        <PeopleIcon />
      </button>

      {showUserSearch && (
        <div className={`user-search-popup popup-align-${popupAlignment}`}>
          <input
            type="text"
            className="form-input"
            placeholder={friendsOnly ? "Search friends..." : "Search users..."}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className="search-results">
            {searchResults.map(user => (
              <div 
                key={user._id}
                className="search-result-item"
                onClick={() => handleUserSelect(user)}
              >
                {user.alias} ({user.email})
              </div>
            ))}
            {searchResults.length === 0 && searchTerm.length >= 2 && (
              <div className="no-results">
                {friendsOnly 
                  ? "No friends found matching your search"
                  : "No users found matching your search"
                }
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSearch;