// client/src/routes/UserRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Messages, Settings, Profile } from 'pages';
import { ViewMessages } from 'components';

const UserRoutes = () => {
 return (
   <Routes>
     <Route path="settings" element={<Settings />} />
     <Route path="messages" element={<Messages />} />
     <Route path="profile" element={<Profile />} />
     <Route path="view-messages" element={<ViewMessages />} />

   </Routes>
 );
};

export default UserRoutes;