// client/src/components/tools/MarkdownRenderer.js
import React from 'react';
import { useMarkdown } from 'hooks/useMarkdown';

const MarkdownRenderer = ({ content, className = '' }) => {
  const { parseMarkdown } = useMarkdown();
  
  if (!content) return null;
  
  return (
    <div className={`prose prose-slate dark:prose-invert max-w-none ${className}`}>
      {parseMarkdown(content)}
    </div>
  );
};

export default MarkdownRenderer;