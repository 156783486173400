// client/src/components/shared/BasePage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PageRoute, MarkdownRenderer } from 'components';

const BasePage = ({ 
  type, 
  subType, 
  tags,
  loadingMessage = 'Loading...', 
  emptyMessage = 'No items found',
  itemWrapper = 'div',
  listWrapper = 'div',
  displayFields = ['name'],
  makeNameLink = true,
  className = '',
  sortOrder = 'sortOrder',
  render 
}) => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const params = { 
          type, 
          subType,
          sort: sortOrder 
        };
        if (tags) {
          params.tags = Array.isArray(tags) ? tags.join(',') : tags;
        }
        const response = await axios.get('/api/pages', { params });
        setItems(response.data);
        setLoading(false);
      } catch (err) {
        setError(`Failed to load ${type.toLowerCase()}`);
        setLoading(false);
        console.error(`Error fetching ${type.toLowerCase()}:`, err);
      }
    };

    fetchItems();
  }, [type, subType, tags, sortOrder]);

  const ItemWrapperComponent = itemWrapper;
  const ListWrapperComponent = listWrapper;

  const content = loading ? (
    <div>{loadingMessage}</div>
  ) : error ? (
    <div>{error}</div>
  ) : items.length === 0 ? (
    emptyMessage
  ) : (
    <ListWrapperComponent className={className}>
      {items.map(item => {
        const summary = item.details?.find(detail => detail.label === "Summary")?.value;
        let contentForMarkdown = '';
        
        if (displayFields.includes('summary') && summary) {
          contentForMarkdown = summary;
        }
        if (displayFields.includes('desc') && item.desc) {
          contentForMarkdown = item.desc;
        }
        
        return (
          <ItemWrapperComponent key={item._id}>
            {displayFields.includes('name') && (
              makeNameLink ? (
                <PageRoute page={item}>
                  <span className="page-name">{item.name}</span>
                </PageRoute>
              ) : (
                <span className="lead-text">{item.name}</span>
              )
            )}
            
            {contentForMarkdown && displayFields.includes('name') && (
              makeNameLink ? 
                ": " : 
                <span className="lead-text">: </span>
            )}

{contentForMarkdown && (
              <MarkdownRenderer 
                content={contentForMarkdown} 
                className="basepage-markdown inline-markdown" 
              />
            )}

          </ItemWrapperComponent>
        );
      })}
    </ListWrapperComponent>
  );

  return render ? render(content) : content;
};

export default BasePage;