// client/src/pages/views/CampaignView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from 'context';
import { 
    DefaultBlock, 
    SectionBlock, 
    DetailsBlock, 
    DetailEditBlock, 
    AdminEditButton, 
    CampaignSetup,
    MemberProtected,
    OwnerProtected, 
    PageTab, 
    PageTabContainer,
    ContributorProtected,
    UserAliasDisplay
} from 'components';

const isValidObjectId = (str) => {
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;
    return objectIdPattern.test(str);
  };

function CampaignView() {    
    const { id } = useParams();
    const api = useApi();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await api.get(`/api/pages/${id}`);
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch equipment');
            } finally {
                setLoading(false);
            }
        };
        fetchPage();
    }, [id, api]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No campaign found</div>;

    return (
        <MemberProtected page={page}>
            <div className="campaign-view"> 
                <h1>{page.name}</h1>
                <hr className='hr-small'/>
                <AdminEditButton id={id} />

                <PageTabContainer>    

                    {/* ------------------------------------------- */}    
                    {/* At a Glance tab                             */}
                    {/* ------------------------------------------- */}    
                    <PageTab title="At a Glance">

                        <div className='image-container2'>
                            <DefaultBlock
                                page={page}  
                                include={["pageImg"]}
                                className='contained-img2'
                            />
                        </div>
                        <div className='two-column-7030'>
                            <div className='grid-item'>
                            {page.details
                                .filter(detail => ["DM", "GM"].includes(detail.label))
                                .map(detail => (
                                    isValidObjectId(detail.value) ? (
                                        <UserAliasDisplay 
                                            key={detail.label} 
                                            page={{ userId: detail.value }} 
                                            fields={["userId"]} 
                                            label={detail.label}
                                            labelClassName='lead-text'
                                            className='left'
                                        />
                                    ) : (
                                        <DetailsBlock 
                                            key={detail.label}
                                            details={[detail]}
                                            className='left'
                                        />
                                    )
                                ))
                            }

                                <DefaultBlock
                                    page={page}  
                                    include={["desc"]}
                                    className='left'
                                />
                            </div>

                            <div className='grid-item'>
                                <h3>Players</h3>
                                <UserAliasDisplay 
                                    page={page} 
                                    fields={["contributors", "members"]} 
                                    removeDuplicates={true} 
                                    layout='block'
                                    className=''
                                />
                            </div>
                        </div>
                    </PageTab>                                              
                    {/* ------------------------------------------- */}    
                    {/* Configure Campagin tab                      */}
                    {/* ------------------------------------------- */} 
                    <PageTab title="Characters">

                    </PageTab>
                    {/* ------------------------------------------- */}    
                    {/* Configure Campagin tab                      */}
                    {/* ------------------------------------------- */} 
                    <ContributorProtected page={page}>
                        <PageTab title="Configure Campaign">
                            <div>                                   
                                <CampaignSetup page={page} />
                            </div>
                        </PageTab>
                    </ContributorProtected>
                    {/* ------------------------------------------- */} 

                </PageTabContainer>
            </div>
        </MemberProtected>
    );
}

export default CampaignView;