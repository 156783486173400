//client/src/components/admin/EditButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context';
import Icons from 'components/tools/Icons';

function AdminEditButton({ id }) {
    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();

    const handleEdit = () => {
        navigate(`/admin/page/edit/${id}`);
    };

    if (!isAuthenticated || !user?.isAdmin) return null;

    return (
        <button 
            className="floating-edit-icon edit-icon" 
            onClick={handleEdit}
            title="Edit Page"
        >
            <Icons.EditIcon />
        </button>
    );
}

export default AdminEditButton;