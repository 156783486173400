//client/src/templates/pageTemplate.js

export const featTemplate = {
    type: 'Rule',
    subType: 'Feat',
    details: [
        { label: 'Prerequisite', value: '' },
        { label: 'Repeatable', value: '' },
        { label: 'Type', value: '' },
        { label: 'Publisher', value: '' },
        { label: 'Source', value: '' },
        { label: 'Summary', value: '' },
        { label: 'isOgl', value: 'FALSE' },
        { label: 'isStock', value: 'FALSE' },
    ]
};


