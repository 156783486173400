// client/src/components/user/friends/FriendRequestActions.js
import React from 'react';
import axios from 'axios';
import { CheckIcon, DeleteIcon } from 'components';

const FriendRequestActions = ({ 
    requestId,
    onAccept,     // Optional callback after successful accept
    onReject,     // Optional callback after successful reject
    inline = true // Controls display style
}) => {
    const handleAccept = async () => {
        try {
            await axios.post(`/api/user/friends/accept/${requestId}`);
            if (onAccept) onAccept(requestId);
        } catch (err) {
            console.error('Failed to accept request:', err);
        }
    };

    const handleReject = async () => {
        try {
            await axios.post(`/api/user/friends/reject/${requestId}`);
            if (onReject) onReject(requestId);
        } catch (err) {
            console.error('Failed to reject request:', err);
        }
    };

    return (
        <div className={inline ? 'inline-flex' : ''}>
            <button onClick={handleAccept} className="icon i24">
                <CheckIcon />
            </button>
            <span>&nbsp;</span>
            <button onClick={handleReject} className="icon i24">
                <DeleteIcon />
            </button>
        </div>
    );
};

export default FriendRequestActions;