//client/src/components/pages/building-blocks/DefaultBlock.js
import React from 'react';
import { MarkdownRenderer } from 'components';

const DefaultBlock = ({ 
  page,
  exclude = [],
  include = [],
  className = '',
  inline = false,
  noDefaultStyles = false,
  titleLevel = 'h1',
  unwrapDivs = false,
  markdownFields = ['desc', 'secDesc'] // Fields that should use markdown rendering
}) => {
  if (!page) return null;

  const shouldShowField = (fieldName) => {
    if (include.length > 0) {
      return include.includes(fieldName) && !exclude.includes(fieldName);
    }
    return !exclude.includes(fieldName);
  };

  const renderTitle = () => {
    switch(titleLevel) {
      case 'h1': return <h1 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h1>;
      case 'h2': return <h2 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h2>;
      case 'h3': return <h3 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h3>;
      case 'h4': return <h4 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h4>;
      case 'h5': return <h5 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h5>;
      case 'h6': return <h6 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h6>;
      case 'span': return <span className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</span>;
      default: return <h1 className={noDefaultStyles ? '' : 'default-block-header'}>{page.name}</h1>;
    }
  };

  const renderField = (fieldName, value) => {
    if (!shouldShowField(fieldName)) return null;

    // Special handling for specific fields
    if (fieldName === 'name') {
      return renderTitle();
    }

    if (fieldName === 'pageImg') {
      return (
        <img 
          src={`/images/${value}`} 
          alt={page.name}
          className={`${noDefaultStyles ? '' : 'page-img'} ${className}`} 
        />
      );
    }

    // Handle markdown fields
    if (markdownFields.includes(fieldName)) {
      return <MarkdownRenderer content={value} />;
    }

    // Handle arrays
    if (Array.isArray(value)) {
      return <div>{value.join(', ')}</div>;
    }

    // Handle objects
    if (typeof value === 'object' && value !== null) {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    }

    // Default text rendering
    return <div>{String(value)}</div>;
  };

  const renderContent = () => {
    return Object.entries(page)
      .filter(([key]) => typeof page[key] !== 'function' && key !== '_id' && key !== '__v')
      .map(([key, value]) => {
        if (!value) return null;
        
        const content = renderField(key, value);
        if (!content) return null;

        return unwrapDivs ? (
          <React.Fragment key={key}>
            {content}
          </React.Fragment>
        ) : (
          <div key={key} className={`${noDefaultStyles ? '' : 'page-field'} ${key}-div`}>
            {content}
          </div>
        );
      });
  };

  return unwrapDivs ? (
    <>{renderContent()}</>
  ) : (
    <div 
      className={`${noDefaultStyles ? '' : 'page-view-container'} ${className}`}
      style={{ display: inline ? 'inline' : 'block' }}
    >
      {renderContent()}
    </div>
  );
};

export default DefaultBlock;