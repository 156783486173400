//client/src/pages/views/SpeciesView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from 'context';
import { DefaultBlock, SectionBlock, DetailsBlock, AbilityRulesList, AdminEditButton } from 'components';

function SpeciesView() {
    const { id } = useParams();
    const api = useApi();
    const [page, setPage] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await api.get(`/api/pages/${id}`);
                setPage(response.data);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch equipment');
            } finally {
                setLoading(false);
            }
        };
        fetchPage();
    }, [id, api]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!page) return <div>No species found</div>;

    return (
        <div className="species-view">

            
<AdminEditButton id={id} />


{/* ------------------------------------------------------------------ */}
{/* Page Content                                                       */}
{/* ------------------------------------------------------------------ */}


{/* ------------------------------------------------------------------ */}
{/* Page Title & Image                                                 */}
{/* ------------------------------------------------------------------ */}
            <div>
                <DefaultBlock 
                    page={page} 
                    include={['name']}
                    className="left" 
                />
                <hr/>
            </div>

            {page.pageImg && (
                <div className="">
                    <DefaultBlock 
                        page={page} 
                        include={['pageImg']}
                        className="img-right img-400-nat"
                        noDefaultStyles={true}
                    />
                </div>
            )}

{/* ------------------------------------------------------------------ */}
{/* Main Description                                                   */}
{/* ------------------------------------------------------------------ */}

            <div className="left">
                <DefaultBlock 
                    page={page} 
                    include={['desc']}
                    className=""
                    />
            </div>

{/* ------------------------------------------------------------------ */}
{/* Main Detail Block                                                  */}
{/* ------------------------------------------------------------------ */}

            <div className='left'>
                <h2>
                <DefaultBlock 
                    page={page} 
                    include={['name']}
                    className="" 
                    noDefaultStyles={true}
                    inline={true}
                    titleLevel="span"
                    unwrapDivs={true}
                />
                  <span> Traits</span></h2>
            </div>

            <div className='left div-max-width-50'>    
                <DetailsBlock 
                    details={page.details} 
                    excludeLabels={['isOgl', 'Source', 'isStock', 'Publisher']} 
                    className=""
                />
            </div>            


            <div className='left'>
            <AbilityRulesList 
                tags={page.name.toLowerCase()}
                displayFields={['name', 'desc']}
                makeNameLink={false}
                itemWrapper='li'
                listWrapper='ul'
            /></div>




{/* ------------------------------------------------------------------ */}
{/* Page Sections                                                      */}
{/* ------------------------------------------------------------------ */}

            <div className="feat-content left">
                <SectionBlock 
                    sections={page.sections}
                    noDefaultStyles={true}
                    />
            </div>



        </div>
    );
}

export default SpeciesView;