//client/src/components/pages/lists/embers/EmbersFeats.js
import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import { useAuth, useApi } from 'context';
import config from 'config';
import { MarkdownRenderer } from 'components';


const EmbersFeats = ({ 
  ruleset, 
  showActive = true, 
  showStock = true, 
  source,
  embersUserId,
  displayStyle = 'h2',
  hideFields = [],
  showFields = [],
  // Class props
  containerClassName = '',
  itemClassName = '',
  titleClassName = '',
  metaClassName = '',
  summaryClassName = '',
  descriptionClassName = ''
}) => {
  const [feats, setFeats] = useState([]);
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const renderTitle = (name, style) => {
    switch(style) {
      case 'h1':
        return <h1 className={titleClassName}>{name}</h1>;
      case 'h2':
        return <h2 className={titleClassName}>{name}</h2>;
      case 'h3':
        return <h3 className={titleClassName}>{name}</h3>;
      case 'h4':
        return <h4 className={titleClassName}>{name}</h4>;
      case 'h5':
        return <h5 className={titleClassName}>{name}</h5>;
      case 'bullet':
        return <li className={titleClassName}>• {name}</li>;
      case 'number':
        return null;
      default:
        return <h2 className={titleClassName}>{name}</h2>;
    }
  };

  const shouldShowField = (fieldName) => {
    if (hideFields.includes(fieldName)) return false;
    if (showFields.length === 0) return true;
    return showFields.includes(fieldName);
  };

  const renderFeatContent = (feat) => {
    return (
      <div key={feat._id} className={itemClassName}>
        {shouldShowField('name') && renderTitle(feat.name, displayStyle)}
        {shouldShowField('source') && shouldShowField('publisher') && (
          <div className={metaClassName}>
            {shouldShowField('source') && feat.source}
            {shouldShowField('source') && shouldShowField('publisher') && ' • '}
            {shouldShowField('publisher') && feat.publisher}
          </div>
        )}
        {shouldShowField('summary') && <div className={summaryClassName}>{feat.summary}</div>}
        {shouldShowField('description') && (
          <MarkdownRenderer content={feat.description} className={descriptionClassName} />
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchFeats = async () => {
      // Special handling for public access
      const isPublicAccess = embersUserId === '58f39ce7bdc1ae0004dbed3d';
      
      // Enforce security rules
      if (!user && !isPublicAccess) {
        setError('Unauthorized access');
        setLoading(false);
        return;
      }

      // If it's public access and source is specified, it must be veylantia.com
      if (isPublicAccess && !user && source && source !== 'veylantia.com') {
        setError('Unauthorized source');
        setLoading(false);
        return;
      }

      try {
        const response = await api.get(`/api/embers/rules`, {
          params: {
            userId: embersUserId || user?.embersId,
            ruleset,
            active: showActive,
            stock: showStock,
            type: 'Feat',
            // For public access, force veylantia.com source
            source: isPublicAccess && !user ? 'veylantia.com' : source
          },
          withCredentials: true
        });

        setFeats(response.data);
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch feats');
      } finally {
        setLoading(false);
      }
    };

    fetchFeats();
  }, [user, ruleset, showActive, showStock, source, embersUserId]);

  if (loading) return <div>Loading feats...</div>;
  if (error) return <div>{error}</div>;
  if (!feats.length) return <div>No feats found</div>;

  return (
    <div className={containerClassName}>
      {displayStyle === 'number' ? (
        <ol className={containerClassName}>
          {feats.map((feat, index) => (
            <li key={feat._id} className={itemClassName}>
              {renderFeatContent(feat)}
            </li>
          ))}
        </ol>
      ) : displayStyle === 'bullet' ? (
        <ul className={containerClassName}>
          {feats.map(feat => renderFeatContent(feat))}
        </ul>
      ) : (
        feats.map(feat => renderFeatContent(feat))
      )}
    </div>
  );
};

export default EmbersFeats;