// client/src/components/user/UserAliasDisplay.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const UserAliasDisplay = ({ 
  page, 
  fields, 
  removeDuplicates = false,
  className = '',
  label,
  labelClassName = 'detail-label',
  layout = 'inline' // inline, block, or comma
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (page.userId) {
        try {
          const response = await axios.get(
            `${config.serverUrl}/api/user/search/byIds`,
            {
              params: { ids: page.userId },
              withCredentials: true
            }
          );
          setUsers(response.data);
          return;
        } catch (error) {
          console.error('Failed to fetch user:', error);
          return;
        }
      }

      const userIds = fields.reduce((acc, field) => {
        return acc.concat(page?.[field] || []);
      }, []);

      if (!userIds.length) return;

      const uniqueIds = removeDuplicates ? [...new Set(userIds)] : userIds;
      
      try {
        const response = await axios.get(
          `${config.serverUrl}/api/user/search/byIds`, 
          { 
            params: { ids: uniqueIds.join(',') },
            withCredentials: true 
          }
        );
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    fetchUsers();
  }, [page, fields, removeDuplicates]);

  if (!users.length) return null;

  const renderUsers = () => {
    switch(layout) {
      case 'block':
        return users.map(user => (
          <div key={user._id} className="detail-value">
            {user.alias}
          </div>
        ));
      case 'comma':
        return users.map((user, index) => (
          <span key={user._id} className="detail-value">
            {user.alias}{index < users.length - 1 ? ', ' : ''}
          </span>
        ));
      case 'inline':
      default:
        return users.map(user => (
          <span key={user._id} className="detail-value">
            {user.alias}
          </span>
        ));
    }
  };

  return (
    <div className={`user-alias-list ${className}`}>
      {label && (
        <>
          <span className={labelClassName}>{label}:</span>
          {' '}
        </>
      )}
      {renderUsers()}
    </div>
  );
};

export default UserAliasDisplay;