//client/src/components/pages/building-blocks/SectionBlock.js
import React from 'react';
import { MarkdownRenderer } from 'components';

const SectionBlock = ({ 
  sections,
  exclude = [],
  sectionNames = [],  // Changed from sectionName to sectionNames
  className = '',
  inline = false,
  noDefaultStyles = false
}) => {
  if (!sections?.length) return null;

  const filteredSections = sectionNames.length > 0
    ? sections.filter(section => sectionNames.includes(section.name))
    : sections;

  if (!filteredSections.length) return null;

  return (
    <div className={`${noDefaultStyles ? '' : 'page-sections'} ${className}`}
         style={{ display: inline ? 'inline' : 'block' }}> 
      {filteredSections.map((section, index) => (
        <div key={index}>
          <div className={noDefaultStyles ? '' : 'two-column-stretch two-column-5050 fill'}>
            <div className={noDefaultStyles ? '' : 'fill left'}>
              {!exclude.includes('name') && section.name && (
                <h3>{section.name}</h3>
              )}

              {!exclude.includes('desc') && section.desc && (
                <div className={noDefaultStyles ? '' : 'section-desc'}>
                  <MarkdownRenderer content={section.desc} />
                </div>
              )}
            </div>

            <div className={noDefaultStyles ? '' : 'rightside-container'}>
              {!exclude.includes('secDesc') && section.secDesc ? (
                <div className={noDefaultStyles ? '' : 'section-secondary'}>
                  {!exclude.includes('secName') && section.secName && (
                    <h4>{section.secName}</h4>
                  )}
                  <div className={noDefaultStyles ? '' : 'section-secondary-desc'}>
                    <MarkdownRenderer content={section.secDesc} />
                  </div>
                </div>
              ) : !exclude.includes('secImg') && section.secImg && (
                <img 
                  src={`/images/${section.secImg}`}
                  alt="Section illustration"
                  className={noDefaultStyles ? '' : 'rightside-img'}
                />
              )}
            </div>
          </div>

          {!exclude.includes('quote') && section.quoteDesc && (
            <div className={noDefaultStyles ? '' : 'quote-container'}>
              {section.quoteImg && (
                <img 
                  src={`/images/${section.quoteImg}`}
                  alt="Section illustration"
                  onError={(e) => {
                    e.target.style.display = 'none';
                  }}
                />
              )}
              <div className={noDefaultStyles ? '' : 'quote-overlay'}>
                <div className={noDefaultStyles ? '' : 'quote-text'}>{section.quoteDesc}</div>
                <hr className={noDefaultStyles ? '' : 'hr-small'} />
                {section.quoteAuth && (
                  <div className={noDefaultStyles ? '' : 'quote-author'}>- {section.quoteAuth}</div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SectionBlock;