// client/src/components/user/building-blocks/AchievementBlock.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from 'context';
import './achievementBlock.css'

/******************************************************************************
* PROP TYPES DEFINITION
* Defines accepted props and their default values for achievement display
******************************************************************************/
const defaultExcludeFields = ['_id', '__v', 'user'];

/******************************************************************************
* COMPONENT DEFINITION
* A reusable component for displaying user achievements with configurable output
* Props:
* - include: fields to show (if empty, shows all non-excluded fields)
* - exclude: fields to hide (defaults to system fields)
* - filterBy: filter by achievement category or type
* - showUnlocked: show only unlocked achievements
* - showLocked: show only locked achievements
* - className: custom CSS classes
* - display: determines wrapper element ('inline', 'break', or 'div')
* - showFloatingText: whether to show tooltip on hover
******************************************************************************/
const AchievementBlock = ({
    include = [],
    exclude = defaultExcludeFields,
    filterBy = '',
    showUnlocked = true,
    showLocked = true,
    className = '',
    display = 'inline',
    showFloatingText = true
}) => {
    const [achievements, setAchievements] = useState([]);
    const [hoveredId, setHoveredId] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const response = await axios.get('/api/user/achievements', {
                    withCredentials: true
                });
                setAchievements(response.data);
            } catch (err) {
                console.error('Failed to fetch achievements:', err);
            }
        };

        if (user) {
            fetchAchievements();
        }
    }, [user]);

    /******************************************************************************
    * FIELD VISIBILITY LOGIC
    * Determines which fields should be displayed based on include/exclude props
    ******************************************************************************/
    const shouldShowField = (fieldName) => {
        if (include.length > 0) {
            return include.includes(fieldName) && !exclude.includes(fieldName);
        }
        return !exclude.includes(fieldName);
    };

    /******************************************************************************
    * ACHIEVEMENT FILTERING
    * Filters achievements based on component props
    ******************************************************************************/
    const filterAchievements = () => {
        return achievements.filter(achievement => {
            // Filter by locked/unlocked status
            if (!showUnlocked && achievement.isUnlocked) return false;
            if (!showLocked && !achievement.isUnlocked) return false;

            // Filter by category or type if specified
            if (filterBy) {
                return achievement.category === filterBy || achievement.type === filterBy;
            }

            return true;
        });
    };

    /******************************************************************************
    * FIELD RENDERING
    * Renders individual achievement fields based on configuration
    ******************************************************************************/
    const renderField = (fieldName, value) => {
        if (!shouldShowField(fieldName)) return null;

        if (fieldName === 'progress') {
            return `${value.current}/${value.target}`;
        }

        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
        }

        return String(value);
    };

    /******************************************************************************
    * WRAPPER ELEMENT SELECTION
    * Returns appropriate wrapper based on display prop
    ******************************************************************************/
    const getWrapper = (content, achievement) => {
        const commonProps = {
            key: achievement._id,
            className: `achievement-item ${achievement.isUnlocked ? 'unlocked' : 'locked'} ${className}`,
            onMouseEnter: () => showFloatingText && setHoveredId(achievement._id),
            onMouseLeave: () => showFloatingText && setHoveredId(null),
            'aria-label': achievement.description
        };

        const tooltipContent = hoveredId === achievement._id && showFloatingText && (
            <div className="achievement-tooltip">
                <div className="tooltip-title">{achievement.name}</div>
                <div className="tooltip-description">{achievement.description}</div>
                {achievement.progress && (
                    <div className="tooltip-progress">
                        Progress: {achievement.progress.current}/{achievement.progress.target}
                    </div>
                )}
            </div>
        );

        switch (display) {
            case 'inline':
                return (
                    <span {...commonProps}>
                        {achievement.icon}
                        {tooltipContent}
                    </span>
                );
            case 'break':
                return (
                    <div {...commonProps} style={{ display: 'block' }}>
                        {achievement.icon}
                        {tooltipContent}
                    </div>
                );
            default:
                return (
                    <div {...commonProps}>
                        {achievement.icon}
                        {tooltipContent}
                    </div>
                );
        }
    };

    const filteredAchievements = filterAchievements();

    return (
        <div className={`achievement-block ${className}`}>
            {filteredAchievements.map(achievement => (
                getWrapper(
                    Object.entries(achievement)
                        .filter(([key]) => shouldShowField(key))
                        .map(([key, value]) => (
                            <div key={key} className={`achievement-field-${key}`}>
                                {renderField(key, value)}
                            </div>
                        )),
                    achievement
                )
            ))}
        </div>
    );
};

export default AchievementBlock;