// client/src/components/user/friends/FriendsList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Protected, FriendDisplay } from 'components';

const FriendsList = ({ 
    showName = false,
    showAlias = true,
    showPicture = false,
    showActions = true,
    onlyFetch = false,
    display = 'inline',  // Can be 'inline', 'break', or 'div'
    className = ''       
}) => {
    const [friends, setFriends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!onlyFetch) {
            fetchFriends();
        }
    }, [onlyFetch]);

    const fetchFriends = async () => {
        try {
            const response = await axios.get('/api/user/friends');
            setFriends(response.data);
            setError('');
        } catch (err) {
            setError('Failed to load friends');
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFriend = async (friendId) => {
        try {
            await axios.delete(`/api/user/friends/${friendId}`);
            setFriends(friends.filter(friend => friend._id !== friendId));
        } catch (err) {
            setError('Failed to remove friend');
        }
    };

    const renderFriend = (friend) => {
        const content = (
            <FriendDisplay
                key={friend._id}
                friend={friend}
                showName={showName}
                showAlias={showAlias}
                showPicture={showPicture}
                showActions={showActions}
                showRemove={showActions} 
                onRemove={handleRemoveFriend}
            />
        );

        switch (display) {
            case 'break':
                return (
                    <React.Fragment key={friend._id}>
                        {content}
                        <br />
                    </React.Fragment>
                );
            case 'div':
                return (
                    <div key={friend._id}>
                        {content}
                    </div>
                );
            case 'inline':
            default:
                return content;
        }
    };

    if (loading) return <div>Loading friends...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Protected>
            <div className={`friends-list ${className}`.trim()}>
                {friends.length === 0 ? (
                    <div>No friends to display</div>
                ) : (
                    friends.map(friend => renderFriend(friend))
                )}
            </div>
        </Protected>
    );
};

export default FriendsList;