//client/src/components/admin/TagInput.js
import React, { useState } from 'react';
import { Icons } from 'components';
import '../../styles/components/tagInput.css'

const TagInput = ({ tags = [], onChange }) => {
    const [newTag, setNewTag] = useState('');

    const handleDeleteTag = (indexToDelete) => {
        const newTags = tags.filter((_, index) => index !== indexToDelete);
        onChange(newTags);
    };

    const handleAddTag = (e) => {
        e.preventDefault();
        if (newTag.trim()) {
            const newTags = [...tags, newTag.trim()];
            onChange(newTags);
            setNewTag('');
        }
    };

    return (
        <div className="tag-input-container">
            <div className="tag-input-row">
                <label className="tag-label">Tags:</label>
                <form onSubmit={handleAddTag} className="tag-input-form">
                    <input
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        placeholder="Add tag..."
                        className="tag-input"
                    />
                    <button type="submit" className="icon i24">
                        <Icons.CreateIcon />
                    </button>
                </form>
                <div className="tag-list">
                    {tags.map((tag, index) => (
                        <span key={index} className="tag">
                            {tag}
                            <button 
                                onClick={() => handleDeleteTag(index)}
                                className="icon i24"
                                type="button"
                            >
                                <Icons.DeleteIcon />
                            </button>
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TagInput;