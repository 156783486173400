// components/messages/MessageContent.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'context';

const MessageContent = ({ message }) => {
    const navigate = useNavigate();
    const api = useApi();

    const handleClick = async (pageId) => {
        try {
            await api.put(`/api/messages/${message._id}/read`);
            window.dispatchEvent(new Event('messagesUpdated'));
            navigate(`/pages/${pageId}`);
        } catch (err) {
            console.error('Error handling message link:', err);
            navigate(`/pages/${pageId}`);
        }
    };

    if (message.type === 'broadcast') {
        const linkMatch = message.message.match(/\[Click here\]\([^)]*\/pages\/([^)]+)\)/);
        if (linkMatch) {
            const pageId = linkMatch[1];
            return (
                <div>
                    A new site update has been posted.{' '}
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        handleClick(pageId);
                    }}>
                        Click here
                    </a>{' '}
                    to read it.
                </div>
            );
        }
    }

    return <div>{message.message}</div>;
};

export default MessageContent;