//client/src/components/admin/CancelSaveButtons.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function CancelSaveButtons({ 
    onSave, 
    saving = false, 
    isDirty = false, 
    customCancel = null 
}) {
    const navigate = useNavigate();

    const handleCancel = () => {
        if (customCancel) {
            customCancel();
        } else {
            navigate(-1);
        }
    };

    return (
        <div className="floating-action-button-container">
            <button 
                type="button" 
                className="floating-action-button secondary"
                onClick={handleCancel}
                disabled={saving}
            >
                Cancel
            </button>
            
            <button 
                type="button"
                className={`floating-action-button ${!isDirty ? 'secondary' : ''}`}
                onClick={onSave}
                disabled={saving || !isDirty}
            >
                {saving ? 'Saving...' : 'Save Changes'}
            </button>
        </div>
    );
}

export default CancelSaveButtons;