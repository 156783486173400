// /client/src/components/UserSettings.js
// /client/src/components/users/UserSettingsContent.js
import React, { useState, useEffect } from 'react';
import { useTheme } from 'hooks';
import { Icons } from 'components';
import './userSettings.css'

const UserSettings = () => {
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { toggleTheme } = useTheme();
    const [isEditingAlias, setIsEditingAlias] = useState(false);
    const [newAlias, setNewAlias] = useState('');
    
    const apiUrl = process.env.NODE_ENV === 'development' 
        ? `${process.env.REACT_APP_API_URL}/api/user/settings`
        : '/api/user/settings';

    const handleDarkModeToggle = async (e) => {
        const newDarkMode = e.target.checked;
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ darkMode: newDarkMode })
            });

            if (!response.ok) {
                throw new Error('Failed to update settings');
            }

            setSettings(prev => ({
                ...prev,
                darkMode: newDarkMode
            }));
            toggleTheme();
        } catch (err) {
            console.error('Error updating dark mode:', err);
            setError(err.message);
        }
    };

    const startEditingAlias = () => {
        setNewAlias(settings.alias);
        setIsEditingAlias(true);
    };



    const saveAlias = async () => {
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ alias: newAlias })
            });

            if (!response.ok) {
                throw new Error('Failed to update alias');
            }

            const data = await response.json();
            setSettings(prev => ({
                ...prev,
                alias: data.alias
            }));
            setIsEditingAlias(false);
        } catch (err) {
            console.error('Error updating alias:', err);
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch(apiUrl, {
                    credentials: 'include',
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch settings');
                }
                
                const data = await response.json();
                setSettings(data);
                setNewAlias(data.alias);
            } catch (err) {
                console.error('Error fetching settings:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, [apiUrl]);

    if (loading) return <div>Loading settings...</div>;
    if (error) return <div>Error loading settings: {error}</div>;

    return (
        <>
            {settings && (
                <div className="settings-grid">
                    <p>Name</p><p>{settings.name || "Not set"}</p>
                    <p>Email</p><p>{settings.email || "Not set"}</p>
                    <p>Alias:</p>
                    {isEditingAlias ? (
                        <div className="alias-edit">
                            <input type="text" value={newAlias} onChange={(e) => setNewAlias(e.target.value)} />
                            <button onClick={saveAlias} className="save-icon"><Icons.SaveIcon /></button>
                        </div>
                    ) : (
                        <div className="alias-edit">
                            <p>{settings.alias}</p>
                            <button onClick={startEditingAlias} className="edit-icon"><Icons.EditIcon /></button>
                        </div>
                    )}
                    <p>Dark Mode:</p>
                    <label className="switch">
                        <input type="checkbox" checked={settings.darkMode} onChange={handleDarkModeToggle} />
                        <span className="slider round"></span>
                    </label>
                </div>
            )}
        </>
    );
};

export default UserSettings;