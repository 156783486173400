// client/src/components/ContentList.js
import React from 'react';
import { BasePage } from 'components';

const ContentList = ({ 
  type,
  subType,
  tags, 
  itemWrapper = 'div', 
  listWrapper = 'div',
  displayFields = ['name'],
  makeNameLink = true,
  className = '',
  sortOrder = 'sortOrder',  
  render  
}) => (
  <BasePage
    type={type}
    subType={subType}
    tags={tags}
    itemWrapper={itemWrapper}
    listWrapper={listWrapper}
    displayFields={displayFields}
    makeNameLink={makeNameLink}
    className={className}
    sortOrder={sortOrder}
    loadingMessage="Loading..."
    emptyMessage=""
    render={render}
  />
);

export default ContentList;