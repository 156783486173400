// client/src/pages/user/Profile.js
import React from 'react';
import { UserSettings, MessageList, FriendsList, FriendRequests, FriendSearch } from 'components';

const Settings = () => {
   return (
        <div>
            
            <div>
                <h1>User Settings</h1>
                <hr className='hr-small'></hr>
            </div>
            
            <div className='three-column-fixed fill'>

                <div className='left'>
                    <UserSettings />
                </div> 
                
                <div className='left'>

                    <h3>Your Friends</h3>
                    <FriendsList 
                        display='div'
                        className='indent'
                    />
                    
                    <h3>Pending Requests</h3>
                    
                    <FriendRequests/>
                    
                    
                    <h3>Find Friends</h3>
                    <FriendSearch/>
                </div>
                
                <div className='left'>
                </div>
            
            </div>

        </div>
   );
};

export default Settings;