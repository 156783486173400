// client/src/components/pages/building-blocks/PageAccessBlock.js
import React from 'react';
import { UserSearch } from 'components';
import './pageAccessBlock.css';

const PageAccessBlock = ({ page, onChange }) => {
    const handleVisibilityChange = (e) => {
        onChange({
            ...page,
            visibility: e.target.value
        });
    };

    const handleContributorAdd = (user) => {
        if (!page.contributors) {
            page.contributors = [];
        }
        
        // Check if user is already a contributor
        if (page.contributors.some(c => c === user._id)) {
            return;
        }

        onChange({
            ...page,
            contributors: [...page.contributors, user._id]
        });
    };

    const handleContributorRemove = (contributorId) => {
        onChange({
            ...page,
            contributors: page.contributors.filter(c => c !== contributorId)
        });
    };

    const handleMemberAdd = (user) => {
        if (!page.members) {
            page.members = [];
        }
        
        // Check if user is already a member
        if (page.members.some(m => m === user._id)) {
            return;
        }

        onChange({
            ...page,
            members: [...page.members, user._id]
        });
    };

    const handleMemberRemove = (memberId) => {
        onChange({
            ...page,
            members: page.members.filter(m => m !== memberId)
        });
    };

    return (
        <div className="page-access-block">
            <div className="visibility-section">
                <label htmlFor="visibility">Page Visibility:</label>
                <select
                    id="visibility"
                    value={page.visibility || 'public'}
                    onChange={handleVisibilityChange}
                    className="form-control"
                >
                    <option value="public">Public</option>
                    <option value="members">Members Only</option>
                </select>
            </div>

            <div className="contributors-section">
                <div className="contributors-header">
                    <label>Contributors:</label>
                    <UserSearch 
                        onUserSelect={handleContributorAdd}
                        buttonClassName="icon i24 add-contributor-btn"
                        popupAlignment="right"
                    />
                </div>
                
                {page.contributors && page.contributors.length > 0 && (
                    <div className="contributors-list">
                        {page.contributors.map(contributorId => (
                            <div key={contributorId} className="contributor-item">
                                <span>{contributorId}</span>
                                <button
                                    onClick={() => handleContributorRemove(contributorId)}
                                    className="remove-button"
                                    aria-label="Remove contributor"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="members-section">
                <div className="members-header">
                    <label>Members:</label>
                    <UserSearch 
                        onUserSelect={handleMemberAdd}
                        buttonClassName="icon i24 add-member-btn"
                        popupAlignment="right"
                    />
                </div>
                
                {page.members && page.members.length > 0 && (
                    <div className="members-list">
                        {page.members.map(memberId => (
                            <div key={memberId} className="member-item">
                                <span>{memberId}</span>
                                <button
                                    onClick={() => handleMemberRemove(memberId)}
                                    className="remove-button"
                                    aria-label="Remove member"
                                >
                                    ×
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageAccessBlock;