//client/src/components/admin/AdminBroadcast.js
import React, { useState, useEffect } from 'react';
import { useApi } from 'context';


const AdminBroadcast = () => {
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [recentBroadcasts, setRecentBroadcasts] = useState([]);
    const api = useApi();

    useEffect(() => {
        fetchBroadcastHistory();
    }, [api]);

    const fetchBroadcastHistory = async () => {
        try {
            const response = await api.get('/api/admin/messages/broadcasts');
            setRecentBroadcasts(response.data);
        } catch (err) {
            console.error('Failed to fetch broadcast history:', err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        setSending(true);
        setError('');
        setSuccess('');

        try {
            await api.post('/api/admin/messages/broadcast', { message });
            setSuccess('Message broadcast successfully!');
            setMessage('');
            fetchBroadcastHistory();
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to send broadcast message');
        } finally {
            setSending(false);
        }
    };

    return (
        <div>
            <h2>Broadcast Message to All Users</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter your broadcast message..."
                        maxLength={5000}
                    />
                    <div>{message.length}/5000</div>
                </div>
                
                {error && <div>{error}</div>}
                {success && <div>{success}</div>}
                
                <button type="submit" disabled={sending || !message.trim()}>
                    {sending ? 'Sending...' : 'Send Broadcast'}
                </button>
            </form>
        </div>
    );
};

export default AdminBroadcast;