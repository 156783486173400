// client/src/routes/AdminRoutes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Admin, CreateRule, CreatePage, CreateNPC, EditPage, BroadcastMessage } from 'pages';
import { AdminProtected } from 'components';
import { featTemplate } from '../templates/pageTemplates';

function AdminRoutes() {
   return (
       <Routes>
           <Route index element={<AdminProtected><Admin /></AdminProtected>} />
           <Route path="page/createfeat" element={<AdminProtected><CreatePage template={featTemplate} /></AdminProtected>} />
           <Route path="page/create-npc" element={<AdminProtected><CreateNPC /></AdminProtected>} />
           <Route path="page/create-rule" element={<AdminProtected><CreateRule/></AdminProtected>} />
           <Route path="page/feat/create" element={<AdminProtected><CreatePage template={featTemplate} /></AdminProtected>} />
           <Route path="page/create" element={<AdminProtected><CreatePage /></AdminProtected>} />
           <Route path="page/edit" element={<Navigate to="/pages" replace />} />
           <Route path="page/edit/:id" element={<AdminProtected><EditPage /></AdminProtected>} />
           <Route path="page/broadcast" element={<AdminProtected><BroadcastMessage /></AdminProtected>} />
       </Routes>
   ); 
}

export default AdminRoutes;