// components/tools/LinkTransformation.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const LinkTransformation = ({ children, wrapInParens }) => {  // Add wrapInParens prop
  const [pageMap, setPageMap] = useState(new Map());
  const [transformedContent, setTransformedContent] = useState(null);
  const contentRef = useRef();

  const getTextContent = () => {
    if (!contentRef.current) return '';
    const text = contentRef.current.textContent || '';
    // Remove parentheses and trim spaces
    const cleanText = text.replace(/[()]/g, '').trim();
    console.log('Clean text content:', cleanText);
    return cleanText;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const text = getTextContent();
      if (!text) return;

      const searchTerms = text.split(',')
      .map(term => term.trim().replace(/[()]/g, '')) // Clean each term
      .filter(Boolean);
      
      if (searchTerms.length === 0) return;

      const fetchPages = async () => {
        try {
          const queryString = searchTerms
            .map(term => `name=${encodeURIComponent(term)}`)
            .join('&');
          
          const response = await axios.get(
            `${config.serverUrl}/api/pages/by-names?${queryString}`, 
            { withCredentials: true }
          );
          
          if (response.data.length > 0) {
            const map = new Map();
            response.data.forEach(page => {
              map.set(page.name.trim(), page._id);
            });
            console.log('Page map:', Array.from(map.entries())); // Single debug point
            setPageMap(map);
          }
        } catch (err) {
          console.error('Error fetching pages:', err);
        }
      };

      fetchPages();
    }, 0);

    return () => clearTimeout(timer);
  }, [children]);

  useEffect(() => {
    if (pageMap.size === 0) return;

    const text = getTextContent();
    if (!text) return;

    const transformedText = text.split(',').map((part, index) => {
      const trimmedPart = part.trim();
      const pageId = pageMap.get(trimmedPart);
      
      if (pageId) {
        return (
          <React.Fragment key={index}>
            {index > 0 && ', '}
            <Link style={{ display: 'inline' }} to={`/pages/${pageId}`}>{trimmedPart}</Link>
          </React.Fragment>
        );
      }
      
      return (
        <React.Fragment key={index}>
          {index > 0 && ', '}
          {trimmedPart}
        </React.Fragment>
      );
    });

    setTransformedContent(transformedText);
  }, [pageMap]);

  if (!children) return null;

  if (!transformedContent) {
    return <span ref={contentRef} style={{ display: 'inline' }}>{children}</span>;
  }

  return (
    <span style={{ display: 'inline' }}>
      {wrapInParens && " ("}
      {transformedContent}
      {wrapInParens && ") "}
    </span>
  );
};


export default LinkTransformation;