// Home.js
import React from 'react';
import { useAuth } from 'context';
import { RecentStories, RecentUpdates, ImageCarousel } from 'components';
import '../components/imageCarousel.css'

const bannerImages = [
  { 
    src: "/images/site/world/places/gallance/army_marches.jpg", 
    alt: "Gallantian Army on the March" 
  },
  { 
    src: "/images/site/world/places/strykara/veil_sanctuary.jpg", 
    alt: "Veil Sanctuary" 
  }
];

const Home = () => {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>

      <div className="text-center">
        <h1>Welcome to Veylantia</h1>
        <div className='two-column fill'>
          <RecentUpdates />
          <RecentStories/>
        </div>
      </div>
    </>
  );
};

export default Home;